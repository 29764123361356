/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, CircularProgress, Divider, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import moment from 'moment';

import {
  buttonStyles,
  FacilityDetailsContainer,
  DetailsContainer,
  ImageContainer,
  DetailsContentContainer,
  DetailsHeadingContainer,
  HeadingText,
  DetailsAndDate,
  Details,
  Date,
  VendorInfoWrapper,
  Match,
  MatchText,
  FacilitiesDescription,
  DescriptionText,
  TitleImgWrapper,
} from './OneTimeJobDetails.Style';
import { DraftStatus, DraftStep, Facility, Vendor } from '../../../../API';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { Loader } from '../JobView/JobsView.styles';
import {
  JobDetails,
  JobExecution,
  checkImageValid,
  formatDate,
  getAuthData,
  getDefaultFormValue,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import { Storage } from 'aws-amplify';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Icon } from '../../../../Shared/Components/Common/PageTitle/PageTitle.styles';
import Modal from '../../Utilities/Shared/Components/Modal';
import { VendorDetailsContainer } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import SearchVendor from '../AddEditOneTimeJob/SearchVendor';
import { FieldNames, IVendor } from '../../Models/AddEditOneTimeJob.Model';
import { UserType } from '../../../../Shared/Constants/App';
import { useForm } from 'react-hook-form';
import JobCreateApiService from '../../Services/JobCreateService';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';

interface IVendorDetailsProps {
  data: {
    jobDetails: any; //JobSummaryV2;
    facility: Facility;
  };
  facilityItem: any;
  fetchJobDetails: (jobId: string) => void;
  isModifyJobDetails?: boolean;
}

const VendorDetails = ({
  data,
  facilityItem,
  fetchJobDetails,
  isModifyJobDetails,
}: IVendorDetailsProps): JSX.Element => {
  const { t } = useTranslation(['dashboard']);
  const [isLoading, setIsLoading] = useState(false);
  const [vendorDetails, setVendorDetails] = useState<Vendor>();
  const [vendorList, setVendorList] = useState<string[]>([]);
  const [isEditDateModalOpen, setIsEditVendorModalOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedVendor, setSelectedVendor] = useState(VendorType.All);
  const [isSearchVendorsSelected, setSearchVendorsSelected] = useState(false);
  const [searchedVendors, setSearchedVendors] = useState<IVendor[]>([
    { vendorId: '', vendorName: '', index: 0, email: '' },
  ]);
  const { handleSubmit } = useForm({
    defaultValues: {
      searchedVendors: getDefaultFormValue(FieldNames.searchedVendors),
    },
  });

  const { divider, executionType, radioButton, radioButtonText, vendorDetailsContent } = buttonStyles;

  const { role } = getAuthData();

  const { status = '', dataStatus = '' } = facilityItem || {};
  const isOpenToBid =
    status === 'Open for bids' || status === 'OpenToBid' || dataStatus === 'OpenToBid' || status === 'ClosedForBid';
  const isAwarded =
    status === 'Awarded' ||
    status === 'Accepted' ||
    status === 'InProgress' ||
    status === 'Completed' ||
    status === 'Created';
  const jobDetails = data.jobDetails || {};
  const isOtjJob = !!facilityItem.jobCustodian;

  const { jobDueDates = [], jobVendors = [], jobId } = jobDetails;
  const bidDueDate = jobDueDates?.find((dueDate: any) => dueDate.mdDueType === 'Bid');

  const validImgRef = useRef({
    valid: false,
    url: '',
  });

  const getVendorDetails = async (vendorId: string) => {
    const vendorResponse = await FacilityApiService.vendorDetails(vendorId);
    if (vendorResponse?.data) {
      return vendorResponse.data;
    } else {
      console.error('workorder query: ', vendorResponse.errors);
    }
    return [];
  };

  const fetchVendors = async () => {
    setIsLoading(true);
    const jobVendors =
      jobDetails.jobVendors?.[0]?.mdVendorType === VendorType.SubVendor
        ? jobDetails.subJobs[0].jobVendors
        : jobDetails.jobVendors;
    if (jobVendors?.length) {
      const vendorInfo = await Promise.all(
        jobVendors.map((dataItem: any) => {
          const { vendorId } = dataItem;
          return getVendorDetails(vendorId);
        })
      );
      const result: string[] = vendorInfo.map((data: any) => data?.vendorCompanyName);
      setVendorList(result);
    }
    setIsLoading(false);
  };

  const fetchAwardedVendor = async () => {
    setIsLoading(true);
    try {
      let workorderResponse;
      if (
        jobDetails?.executionType === JobExecution.SubVendor &&
        (jobDetails.estimationType === JobDetails.EstimateWithinPeazy ||
          jobDetails.estimationType === JobDetails.EstimateOutsidePeazy)
      ) {
        const subJobId = jobDetails?.subJobs?.[0]?.jobId ?? jobDetails?.subJobId;
        workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: subJobId });
      } else {
        workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId });
      }
      if (workorderResponse?.data?.length) {
        const { vendorId } = workorderResponse.data[0];
        const vendorResult = await getVendorDetails(vendorId);
        setVendorImage(vendorId);
        setVendorDetails(vendorResult);
      } else {
        console.error('workorder query: ', workorderResponse.errors);
      }
    } catch (e) {
      console.error('Error', e);
    }
    setIsLoading(false);
  };

  const handleOpenEditVendorModal = () => {
    setIsEditVendorModalOpen(true);
  };

  const handleClose = () => {
    setIsEditVendorModalOpen(!isEditDateModalOpen);
  };

  const renderEditVendorHeaderContent = () => {
    return <HeadingText>{t('oneTimeJob:EditVendorDetails')}</HeadingText>;
  };

  const handleVendorDetailsCheckboxChange = (e: any) => {
    setSelectedVendor(e.target.value);
    setSearchVendorsSelected(e.target.value === 'SearchVendors');
  };

  const onVendorSelect = (vendors: IVendor[]) => {
    setSearchedVendors(vendors);
  };

  const renderEditVendorBodyContent = () => {
    return (
      <>
        <VendorDetailsContainer>
          <Box
            sx={{
              ...vendorDetailsContent,
              marginBottom: '1.5rem',
              marginTop: '1.5rem',
            }}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                sx={executionType}
                row
                aria-label="vendor-details"
                name="vendor-details-radio-button"
                value={selectedVendor}
                onChange={handleVendorDetailsCheckboxChange}>
                <Box>
                  <FormControlLabel
                    value="All"
                    control={
                      <Radio
                        sx={{
                          ...radioButton,
                        }}
                      />
                    }
                    label={t('oneTimeJob:availableVendors')}
                    sx={{
                      ...radioButtonText,
                    }}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="SearchVendors"
                    control={
                      <Radio
                        sx={{
                          ...radioButton,
                        }}
                      />
                    }
                    label={t('oneTimeJob:searchVendors')}
                    sx={{
                      ...radioButtonText,
                    }}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          {isSearchVendorsSelected && (
            <>
              <SearchVendor
                fieldName={FieldNames.searchedVendors}
                selectedVendors={searchedVendors}
                onVendorSelect={onVendorSelect}
                isMulti={role === UserType.Customer}
                inPopup={true}
              />
            </>
          )}
        </VendorDetailsContainer>
      </>
    );
  };

  const handleChangeVendors = async () => {
    setIsLoading(true);
    const customerDetails = await Promise.resolve(getStoredCustomerDetails());
    let customerId = '';
    if (role === UserType.Customer) {
      ({ customerId } = customerDetails || {});
    }

    try {
      await handleJobUpdateVendors(customerId, jobId, customerDetails);
      await JobCreateApiService.updateJob(customerId, jobId, {
        draftStatus: DraftStatus.C,
        draftStep: DraftStep.JobSummary,
      });
      setIsEditVendorModalOpen(false);
      fetchJobDetails(jobId);
      setIsLoading(false);
    } catch (error) {
      handleApiError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiError = () => {
    setSnackbarMessage(t('message:errorWhileUpdatingJobDate'));
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setIsLoading(false);
    return false;
  };
  const getCustomerSelectedVendorsPayload = (vendors: IVendor[]) => {
    if (selectedVendor === VendorType.All) {
      return vendors?.map(() => ({
        mdVendorType: VendorType.All,
        vendorId: '',
      }));
    } else {
      return vendors?.map((vendorItem: IVendor) => ({
        mdVendorType: VendorType.SearchVendors,
        vendorId: vendorItem.vendorId,
      }));
    }
  };

  const updateVendors = useCallback(
    async (customerId: string, jobId: string, vendors: IVendor[]) => {
      let vendorsPayload: any = [];
      if (role === UserType.Customer) {
        vendorsPayload = getCustomerSelectedVendorsPayload(vendors);
      }
      return await JobCreateApiService.addJobVendors(customerId, jobId, vendorsPayload);
    },
    [selectedVendor]
  );

  const handleJobUpdateVendors = async (customerId: string, jobId: string, customerDetails: any) => {
    const vendorsList =
      selectedVendor === VendorType.SearchVendors && searchedVendors[0].vendorId
        ? searchedVendors
        : [
            {
              index: 1,
              vendorId: customerDetails.customerId,
              vendorName: customerDetails.organizationName,
              email: '',
            },
          ];

    await updateVendors(customerId, jobId, vendorsList);
  };

  useEffect(() => {
    if (isAwarded) {
      fetchAwardedVendor();
    }
    if (
      isOpenToBid &&
      (jobVendors?.[0]?.mdVendorType === VendorType.SearchVendors ||
        jobVendors?.[0]?.mdVendorType === VendorType.SubVendor)
    ) {
      fetchVendors();
    }
  }, [data, facilityItem]);

  const setVendorImage = async (vendorId: string) => {
    const vendorImg = await Storage.get(`vendor/${vendorId}/company-logos/${vendorId}`, { level: 'public' });

    checkImageValid(
      vendorImg,
      function () {
        validImgRef.current.valid = true;
      },
      function () {
        validImgRef.current.valid = false;
      }
    );
    const url = validImgRef.current.valid ? vendorImg : Icons.VendorDetailsLogo;
    validImgRef.current.url = url;
  };

  const renderVendorInfoWrapper = () => {
    return (
      <VendorInfoWrapper>
        <Match>
          <MatchText>{isAwarded ? t('dashboard:awardedTo') : t('dashboard:bidOpenTo')}</MatchText>
        </Match>
        <FacilitiesDescription>
          <DescriptionText>
            {isAwarded
              ? vendorDetails?.vendorCompanyName
              : isOpenToBid && jobVendors?.[0]?.mdVendorType === VendorType.All
                ? 'All available vendors on Peazy'
                : isOpenToBid &&
                    (jobVendors?.[0]?.mdVendorType === VendorType.SearchVendors ||
                      jobVendors?.[0]?.mdVendorType === VendorType.SubVendor)
                  ? vendorList.join(', ')
                  : '-'}
          </DescriptionText>
        </FacilitiesDescription>
      </VendorInfoWrapper>
    );
  };

  return (
    <FacilityDetailsContainer>
      <DetailsContainer>
        <TitleImgWrapper>
          <ImageContainer>
            <img src={validImgRef.current.url || Icons.VendorDetailsLogo} alt={t('altTexts:vendorLogo')} />
          </ImageContainer>
          <DetailsHeadingContainer>
            <HeadingText>{t('dashboard:vendorDetails')}</HeadingText>
            {isModifyJobDetails && (
              <Icon
                onClick={() => handleOpenEditVendorModal()}
                sx={{ marginLeft: '1em', width: '1.3rem', height: '1.3rem' }}
                src={Icons.EditBlack}
                alt={t('altTexts:modify')}
              />
            )}
          </DetailsHeadingContainer>
        </TitleImgWrapper>
        <DetailsContentContainer>
          {isOpenToBid &&
          ((isOtjJob &&
            facilityItem?.executionType === 'subVendor' &&
            facilityItem?.estimationType === 'estimateWithinPeazy') ||
            !isOtjJob) &&
          moment(bidDueDate?.dueDate).isSameOrAfter(moment(), 'day') ? (
            <DetailsAndDate>
              <Details>{bidDueDate?.dueDate ? formatDate(bidDueDate.dueDate) : ''}</Details>
              <Date>{t('dashboard:bidOpenTill')}</Date>
            </DetailsAndDate>
          ) : null}
        </DetailsContentContainer>
      </DetailsContainer>
      <Divider sx={divider} />
      <Modal
        open={isEditDateModalOpen}
        onClose={handleClose}
        primaryButtonLoading={isLoading}
        primaryButtonDisabling={
          selectedVendor !== VendorType.All ? (searchedVendors[0]?.vendorId ? false : true) : false
        }
        primaryButtonLabel={t('oneTimeJob:modifyVendorDetails')}
        primaryButtonVisible={true}
        secondaryButtonLabel={t('assignJob:cancel')}
        secondaryButtonVisible
        renderHeader={renderEditVendorHeaderContent()}
        renderBody={renderEditVendorBodyContent()}
        primaryAction={handleSubmit(handleChangeVendors)}
        secondaryAction={handleClose}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />

      {isLoading ? (
        <Loader>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : (
        <>{renderVendorInfoWrapper()}</>
      )}
    </FacilityDetailsContainer>
  );
};

export default VendorDetails;
