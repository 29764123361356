/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import {
  jobServices,
  jobSummary,
  jobsV2,
  jobSummaryV2,
  allVendorsOnARK,
  getTags,
  allJobAttachments,
  getJobsForPrimeVendor,
  referenceJobs,
  allJobAttachmentsForBidSummary,
  getComments,
  allBidSubmissionsForJobId,
  jobBids,
  jobBid,
  conversations,
  getUserScopes,
} from '../../../graphql/queries';
import {
  addJob,
  addJobFacility,
  addJobServices,
  addJobFrequency,
  updateJob,
  addJobVendors,
  openJobForBid,
  addJobDueDates,
  createJobAttachments,
  updateJobAttachments,
  deleteJobAttachments,
  createSubJob,
  markJobAsAwarded,
  addComments,
  deleteJobs,
  createConversation,
} from '../../../graphql/mutations';

import {
  CreateJobFacilityInput,
  CreateJobInput,
  UpdateJobInput,
  CreateJobServiceInput,
  CreateJobVendorInput,
  CreateJobFrequencyInput,
  CreateJobDueDateInput,
  JobCustomerInput,
  JobStatus,
  Family,
  CreateOrUpdateJobAttachmentInput,
  AllJobAttachmentInput,
  JobsForPrimeVendorInput,
  CreateSubJobInput,
  facilityAddressInput,
  CommentsInput,
  DeleteType,
  ConversationInput,
} from '../../../API';
import { jobSummaryMock, jobsV2Mock } from '../Components/IssuesRaised/MockData';
import { isEmpty } from 'lodash';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

interface IGetJobs {
  customerId: string;
  dataStatus?: JobStatus | '';
  facilityId?: string;
  dueDate?: string;
  mdDueType?: string;
  pageNumber?: number;
  limit?: number;
}

interface IGetConversations {
  customerId?: string;
  vendorId?: string;
  jobId?: string;
  bidId?: string;
  bidVersion?: string;
  conversationId?: string;
  pageNumber?: number;
  limit?: number;
}

export default class JobCreateApiService {
  static async getUserScopes(userId: string, facilityId?: string) {
    try {
      const variables: { facilityId?: string; userId: string } = {
        userId,
      };
      if (facilityId) {
        variables.facilityId = facilityId;
      }

      const userResponse: IGraphQLResponse = await API.graphql({
        query: getUserScopes,
        variables: { ...variables },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: userResponse?.data?.getUserScopes ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async createJob(customerId: string, jobsInput?: CreateJobInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJob,
          variables: { customerId, jobsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJob ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async createSubJob(subJobInput?: CreateSubJobInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: createSubJob,
          variables: { createSubJobInput: subJobInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.createSubJob ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async deleteJobs(jobIds: Array<string | null>, deleteType: DeleteType | null) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: deleteJobs,
          variables: { deleteJobsInput: { jobIds, deleteType: deleteType ?? DeleteType.Soft } },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.deleteJobs ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobFacility(customerId: string, jobId: string, jobFacilityInput?: CreateJobFacilityInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobFacility,
          variables: { customerId, jobId, jobFacilityInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobFacility ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobServices(customerId: string, jobId: string, jobServicesInput?: CreateJobServiceInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobServices,
          variables: { customerId, jobId, jobServicesInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobServices ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobVendors(customerId: string, jobId: string, createJobVendorInput?: CreateJobVendorInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobVendors,
          variables: { customerId, jobId, createJobVendorInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobVendors ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobDueDates(customerId: string, jobId: string, createJobDueDatesInput?: CreateJobDueDateInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobDueDates,
          variables: { customerId, jobId, createJobDueDatesInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobDueDates ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async updateJob(customerId: string, jobId: string, jobsInput?: UpdateJobInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: updateJob,
          variables: { customerId, jobId, jobsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.updateJob ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getJobsForPrimeVendor(
    organizationId: string,
    jobForDmInput: JobsForPrimeVendorInput,
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: string
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const firstPageResponse: IGraphQLResponse = await API.graphql(
        {
          query: getJobsForPrimeVendor,
          variables: { organizationId, jobForDmInput, limit, pageNumber, sortBy, sortOrder },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );

      return { data: firstPageResponse.data?.getJobsForPrimeVendor ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getSelectedJobServices(customerId: string, jobId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobServices,
          variables: { customerId, jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.jobServices ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async jobsV2(jobs: IGetJobs, isMocked = false) {
    if (isMocked) {
      return jobs.facilityId === '25c3b028-cc81-4eeb-95e0-c55a54b73532'
        ? { data: jobsV2Mock, errors: [] }
        : { data: [], errors: [] };
    }

    const variables: IGetJobs = {
      customerId: jobs.customerId,
      dataStatus: jobs.dataStatus,
      facilityId: jobs.facilityId,
      dueDate: jobs.dueDate,
      mdDueType: jobs.mdDueType,
      pageNumber: jobs.pageNumber || 1,
      limit: jobs.limit,
    };

    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobsV2,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );

      const data = response.data?.jobsV2?.data ?? [];
      const metaData = response.data?.jobsV2?.metadata ?? [];
      const errors: string[] = []; // Specify the type as an array of strings

      return { data, metaData, errors };
    } catch (e: any) {
      const errors: string[] = e.errors || []; // Specify the type as an array of strings
      return { data: null, errors };
    }
  }

  static async jobBidResponse(jobId: string, vendorId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobBid,
          variables: { jobId, vendorId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.jobBid ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  // static async getJobs(customerId: string, facilityId: string) {
  //   try {
  //     const response: IGraphQLResponse = await API.graphql(
  //       {
  //         query: jobs,
  //         variables: { customerId, facilityId },
  //         authMode: AWSAuthMode.COGNITO,
  //       },
  //       {...getWebInfo(), ...authHeaders}
  //     );
  //     return { data: response.data?.jobs ?? [], errors: [] };
  //   } catch (e: any) {
  //     return { data: null, errors: e.errors };
  //   }
  // }

  static async addJobFrequency(customerId: string, jobId: string, jobFrequencyInput: CreateJobFrequencyInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobFrequency,
          variables: { customerId, jobId, jobFrequencyInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobFrequency ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async jobSummary(customerId: string, jobId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobSummary,
          variables: { customerId, jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.jobSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async openJobForBid(customerId: string, jobId: string, jobCustomerInput: JobCustomerInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: openJobForBid,
          variables: { customerId, jobId, jobCustomerInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.openJobForBid ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async jobSummaryV2(customerId: string, jobId: string, isMocked = false) {
    if (isMocked) {
      const oneTimeJobs = localStorage.getItem('oneTimeJobs');
      if (oneTimeJobs) {
        const parsedJobs = JSON.parse(oneTimeJobs);
        const selectedJob = parsedJobs.find((jobItem: any) => jobItem.jobDetails.jobId === jobId);
        return { data: selectedJob?.jobDetails ?? [], errors: [] };
      }
      return { data: null, errors: [] };
    }
    if (!isMocked) {
      try {
        const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
        const response: IGraphQLResponse = await API.graphql(
          {
            query: jobSummaryV2,
            variables: { customerId, jobId },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        return { data: response.data?.jobSummaryV2 ?? [], errors: [] };
      } catch (e: any) {
        return { data: null, errors: e.errors };
      }
    }
    return { data: jobSummaryMock, errors: [] };
  }

  static async getAllVendorsOnARK(name: string, dataStatus: string, otherVendorSearchInput: object) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allVendorsResponse: IGraphQLResponse = await API.graphql(
        {
          query: allVendorsOnARK,
          variables: { name, dataStatus, otherVendorSearchInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allVendorsResponse?.data.allVendorsOnARK ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getRegionalJobs(vendorId: string, id: string, isMocked = false) {
    if (isMocked) {
      const allJobs = localStorage.getItem('oneTimeJobs');
      if (allJobs) {
        return JSON.parse(allJobs);
      }
      return [];
    }
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allJRegionalJobs: IGraphQLResponse = await API.graphql(
        {
          query: referenceJobs,
          variables: { vendorId, userId: id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allJRegionalJobs.data.referenceJobs ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getTags(family: Family, jobId?: string, owner?: string, tagsText?: string, includeSystemTags?: string) {
    const variables: { family: Family; includeSystemTags?: string; jobId?: string; owner?: string; tagsText?: string } =
      { family };
    if (jobId) {
      variables.jobId = jobId;
    }
    if (tagsText) {
      variables.tagsText = tagsText;
    }
    if (includeSystemTags) {
      variables.includeSystemTags = includeSystemTags;
    }
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      let getTagsResponse: any = {};
      if (!isEmpty(owner)) {
        getTagsResponse = await API.graphql(
          {
            query: getTags,
            variables: { ...variables, owner },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        if (isEmpty(getTagsResponse?.data?.getTags ?? [])) {
          getTagsResponse = await API.graphql(
            {
              query: getTags,
              variables,
              authMode: AWSAuthMode.COGNITO,
            },
            { ...getWebInfo(), ...authHeaders }
          );
        }
      } else {
        getTagsResponse = await API.graphql(
          {
            query: getTags,
            variables,
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
      }
      return {
        data: getTagsResponse.data.getTags ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async createJobAttachment(
    jobId: string,
    facilityIds: string,
    createOrUpdateJobAttachmentInput: CreateOrUpdateJobAttachmentInput[]
  ) {
    try {
      const variables = {
        createOrUpdateJobAttachmentInput,
        jobId,
        facilityIds,
      };
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const attachmentResponse: any = await API.graphql(
        {
          query: createJobAttachments,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: attachmentResponse.data.createJobAttachments ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateJobAttachment(
    jobId: string,
    facilityIds: string,
    createOrUpdateJobAttachmentInput: CreateOrUpdateJobAttachmentInput[]
  ) {
    try {
      const variables = {
        createOrUpdateJobAttachmentInput,
        jobId,
        facilityIds,
      };
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const attachmentResponse: any = await API.graphql(
        {
          query: updateJobAttachments,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: attachmentResponse.data.updateJobAttachments ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllJobAttachments(allJobAttachmentInput: AllJobAttachmentInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const getAllJobAttachmentsResponse: any = await API.graphql(
        {
          query: allJobAttachments,
          variables: { allJobAttachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return {
        data: getAllJobAttachmentsResponse.data.allJobAttachments ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async deleteJobAttachments(jobId: string, attachmentIds: string[]) {
    try {
      const variables = {
        jobId,
        attachmentIds,
      };
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const deleteAttachmentResponse: any = await API.graphql(
        {
          query: deleteJobAttachments,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: deleteAttachmentResponse.data.deleteJobAttachments ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
  static async markJobAsAwarded(jobId: string, bidId: string, facilityAddress: facilityAddressInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: markJobAsAwarded,
          variables: { jobId, bidId, facilityAddress },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.markJobAsAwarded ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getAllJobAttachmentsForBidSummary(allJobAttachmentInput: AllJobAttachmentInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobAttachmentsForBidSummary,
          variables: { allJobAttachmentInput },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allJobAttachmentsForBidSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addComments(keyId: string, commentsInput: CommentsInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addComments,
          variables: { keyId, commentsInput },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addComments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getComments(keyId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: getComments,
          variables: { keyId },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.getComments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async allBidSubmissionsForJobId(jobId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allBidSubmissionsForJobId,
          variables: { jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allBidSubmissionsForJobId ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getJobBids(jobId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const jobBidsResponse: IGraphQLResponse = await API.graphql(
        {
          query: jobBids,
          variables: { jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: jobBidsResponse?.data?.jobBids ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async getConversations(conversationsInput: IGetConversations) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const conversationsResponse: IGraphQLResponse = await API.graphql(
        {
          query: conversations,
          variables: { ...conversationsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: conversationsResponse?.data?.conversations?.data ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async createConversation(conversationInput: ConversationInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const createConversationsResponse: IGraphQLResponse = await API.graphql(
        {
          query: createConversation,
          variables: { conversationInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: createConversationsResponse?.data?.createConversation ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }
}
