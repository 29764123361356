/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MRT_SortingState } from 'material-react-table';
import { Divider, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { getJobSize } from '../../Utilities/Jobs.Utils';
import {
  deepClone,
  fetchDataFromS3Bucket,
  formatDaysLeft,
  getJobAddress,
  getJobFacility,
  getStoredCustomerDetails,
  handleFilter,
  handleSort,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../../../Shared/Utilities/utils';

import { ActionTypes, SortByType } from '../../../../Shared/Constants/App';

import MarketplaceApiService from '../../Services/marketplaceServices';
import BidCreationApiService from '../../Services/BidCreationApiService';
import { JobInterestsActionType, JobsQueryType, ServiceMeasure } from '../../../../API';

import { GetAllJobs, JobType, MarketplaceTabs, SortingItem } from '../../Models/Marketplace.model';
import { FilterItem } from '../../../Jobs/Components/JobsListView/JobsListView';

import DataGridComponent from './BaseTable';
import useColumns from './useColumns';
import { isEmpty } from 'lodash';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import {
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

const NotInterestedJobs = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);

  const [sortBy, setSortBy] = useState('');
  const [clickedRow, setClickedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [jobs, setJobs] = useState<any[]>([]);
  const [jobInterests, setJobInterests] = useState<any[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [newSort, setNewSort] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([]);

  const navigate = useNavigate();

  const hasFetchedDataRef = useRef(false);

  const myCustomSortingFn = (): void => {
    setSortBy(SortByType.DESC);
  };

  const onSortChange = async (sortingObj: SortingItem[]): Promise<void> => {
    await handleSort(
      sortingObj,
      newSortOrder,
      setSorting,
      setNewSortOrder,
      setNewSort,
      getJobsData,
      hasFetchedDataRef,
      newSort,
      currentPage,
      itemsPerPage
    );
  };

  const onFilterChange = async (filtersObj: FilterItem[]): Promise<void> => {
    await handleFilter(
      filtersObj,
      setColumnFilters,
      getJobsData,
      currentPage,
      itemsPerPage,
      newSortOrder,
      hasFetchedDataRef,
      newSort
    );
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getAttachments = async (attachments: any[] = []) => {
    let photos: any[] = [];
    let videos: any[] = [];
    if (attachments?.length) {
      const attachmentsList = await Promise.all(
        attachments?.map(async (attachment: any) => {
          if (attachment?.url && !attachment?.url?.includes('https')) {
            attachment.url = await fetchDataFromS3Bucket(navigate, attachment?.url ?? '');
          }
          return attachment;
        })
      );
      const imagesList: any[] = [];
      const videosList: any[] = [];
      if (attachmentsList) {
        attachmentsList?.forEach((attachment: any) => {
          if (attachment?.mdAttachmentType?.includes('image')) {
            imagesList?.push(attachment);
          } else if (attachment?.mdAttachmentType?.includes('video')) {
            videosList?.push(attachment);
          }
        });
      }
      photos = [...photos, ...imagesList];
      videos = [...videos, ...videosList];
    }

    return { photos, videos };
  };

  const transformJobsData = async (data: GetAllJobs[], customerId: string) => {
    return Promise.all(
      data?.map(async (selectedBid: GetAllJobs) => {
        const jobBids = selectedBid?.jobBids;
        const jobFacility = getJobFacility(selectedBid);
        const address = getJobAddress(selectedBid);
        const services = selectedBid?.jobServices?.map((service) => service?.mdServiceName).join(', ');
        const { photos, videos } = await getAttachments(selectedBid?.jobAttachments);

        return {
          ...selectedBid,
          id: selectedBid?.bidId ?? '',
          bidDueDate: selectedBid?.dueDate,
          createdOn: selectedBid?.jobCreatedOn,
          area: selectedBid?.totalSqftArea ?? selectedBid?.totalUnitsArea,
          location: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
          facilityAddress: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
          facilityName: selectedBid?.facilityBuildingName ?? '',
          bidId: selectedBid?.bidId ?? '',
          vendorId: customerId,
          jobFacility: jobFacility,
          facilityType: selectedBid?.jobFacilities?.[0]?.mdFacilityType,
          jobBids: jobBids,
          jobServices: selectedBid?.jobServices,
          address: address,
          startDate: selectedBid?.jobStartDate,
          stopDate: selectedBid?.jobStopDate,
          duration: selectedBid?.durationOfTheJob,
          noOfDaysLeft: formatDaysLeft(selectedBid?.daysLeft as number),
          volume: getJobSize(selectedBid?.jobSize as ServiceMeasure),
          mdServiceName: services,
          specialInstructions: selectedBid?.specialInstructions,
          photosAndVideos: { photos, videos },
          status: selectedBid?.jobDataStatus,
          type: JobType.All,
        };
      })
    );
  };

  const getJobsData = async (
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: boolean,
    filterBy?: string,
    filter?: string
  ) => {
    try {
      setIsLoading(true);
      const sortOrderString = sortOrder ? 'desc' : sortBy ? 'asc' : undefined;

      const { customerId } = getStoredCustomerDetails();

      const allJobs = await MarketplaceApiService.AllJobs(
        customerId,
        JobsQueryType.NotInterested,
        pageNumber,
        limit ?? NUMBER_OF_ITEMS_PER_PAGE,
        sortBy,
        sortOrderString,
        filterBy,
        filter
      );

      if (allJobs?.errors?.length) {
        setSnackbarMessage(allJobs?.errors?.[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
      }

      if (allJobs?.data && allJobs?.errors?.length === 0) {
        const modifiedData = await transformJobsData(allJobs?.data, customerId);
        setJobInterests(allJobs?.data || []);
        if (!isEmpty(modifiedData)) {
          setJobs(modifiedData);
          setTotalCount(allJobs?.metadata?.totalCount);
          setIsLoading(false);
        } else {
          setJobs([]);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  const handleOnClickInterestedOrNot = async (jobId: string, actionType: JobInterestsActionType) => {
    setIsLoading(true);
    const { customerId } = getStoredCustomerDetails();
    let addedJobInterestResponse;
    if (jobInterests.findIndex((value) => value.jobId === jobId) === -1) {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: [
          {
            jobId,
            actionType,
          },
        ],
      };
      addedJobInterestResponse = await BidCreationApiService.addJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    } else {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: {
          jobId,
          actionType,
        },
      };
      addedJobInterestResponse = await BidCreationApiService.updateJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    }
    if (addedJobInterestResponse.data && addedJobInterestResponse.errors.length === 0) {
      getJobsData();
    } else {
      setIsLoading(false);
      setSnackbarMessage(addedJobInterestResponse.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderActionsCell = (row: any): JSX.Element => {
    return (
      <ActionsColumn>
        <WhiteTooltip title={t('vendor:more')}>
          <IconButton
            key={row.index}
            sx={{
              backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              handleClick(e);
              setClickedRow(row.index);
              setRowInfo(row.original);
            }}
            size="large"
            aria-haspopup="true"
            {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
            <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
          </IconButton>
        </WhiteTooltip>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking
            to={{ pathname: `/onetimejobdetails`, search: `?id=${rowInfo.jobId}` }}
            state={{
              facilityItem: { ...rowInfo },
              facility: { ...rowInfo.jobFacility, address: { addressName: rowInfo.address } },
              job: rowInfo,
            }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t(ActionTypes.TextDetails)} /> {t('dashboard:viewJobDetails')}
            </Menus>
          </Linking>
          <>
            <Divider />
            <Menus
              onClick={() => handleOnClickInterestedOrNot(rowInfo.jobId, JobInterestsActionType.Interested)}
              style={{ paddingLeft: '23px' }}>
              <img src={Icons.TickIcon} alt={t('altTexts:notInterested')} />
              {t('vendor:interested')}
            </Menus>
          </>
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const { columns, media } = useColumns(MarketplaceTabs.NotInterestedJobs);

  const NotInterestedJobsColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(16, 0, {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 70,
      Cell: ({ row }: any) => renderActionsCell(row),
    });
    return jobColumns;
  }, [columns]);

  useEffect(() => {
    if (!hasFetchedDataRef.current && !sorting.length && !columnFilters.length) {
      getJobsData(currentPage, itemsPerPage);
      hasFetchedDataRef.current = true;
    }
  }, [sorting, columnFilters]);

  return (
    <>
      <DataGridComponent
        columns={NotInterestedJobsColumns}
        data={jobs}
        sorting={sorting}
        sortBy={sortBy}
        isLoading={isLoading}
        handleSort={onSortChange}
        myCustomSortingFn={myCustomSortingFn}
        handleChange={handleChange}
        totalCount={totalCount}
        handleApi={getJobsData}
        currentPage={currentPage}
        newSortOrder={newSortOrder}
        newSort={newSort}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        filterJobs={onFilterChange}
      />
      {media}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default NotInterestedJobs;
