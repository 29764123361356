/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState, isValidElement } from 'react';
import { Box, IconButton, Snackbar, Stack, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useLocation } from 'react-router-dom';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import {
  ActionsColumn,
  JobLink,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { SnackBarWrapper, TabSection, TabTitle } from '../JobDetailsOverView/JobDetailsOverview.styles';
import { TaskData } from '../WorkTasks/WorkTasks';
import { IJobOverview } from '../JobsListView/JobsListView';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { TabsWrapper } from './JobsView.styles';

import { Icons } from '../../../../Shared/Constants/Icons';
import { JobType, WorkOrderStatus, JobStatus, BidDraftStep } from '../../../../API';

import { formatDate, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import theme from '../../../../Shared/Themes/theme';
import JobViewApiService from '../../Services/JobViewService';
import JobCreateApiService from '../../Services/JobCreateService';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import DeleteJobs from '../DeleteJobDialog/DeleteJobDialog';
import { JobStatuses, DATA_STATUS, SortOrderType } from '../../../../Shared/Constants/App';
import { isEmpty } from 'lodash';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { IJobRow, TabPanelProps } from '../../Models/JobsView.Model';

export interface IJobs {
  facilityName: string;
  label: string;
  noOfService: number;
  status: string;
  startDate: string;
  stopDate?: string;
  jobType: string;
  facilityType: string;
  location: string;
  createdOn: string;
  area: string;
  duration: string;
  volume: string;
  percentageMatch: number;
  noOfDaysLeft: number;
  bidStatus: string;
  bidEstimate: number;
  lastActivity: string;
  frequency: string;
  serviceCategory: any;
  facilityStatus: string;
  areaSpecification: string;
  numOfHours: number;
  ratePerHour: number;
  costPerService: number;
  servicesPerWeek: number;
  weeklyCost: number;
  profitPerWeek: number;
  weeklyBid: number;
  numOfWeeks: number;
  totalServices: number;
  totalJobEstimation: number;
  jobStatus: string;
  bidCount: number;
  totalTasks: number;
  approvedByCustomer: number;
  sortingFn?: any;
  facility?: any;
  address?: any;
  jobId?: string;
  executionType?: string;
  customerId?: string;
}

export interface IJobVendorDetails {
  mdVendorType: string;
  vendorId: string;
}

export interface IBidSummaryResponse {
  bidVersion: number;
  profitMargin: number;
  isFlatMargin: string;
  vendorId: string;
}

export interface IJobBids {
  bidId: string;
  dataStatus: string;
  draftStep: BidDraftStep;
}

export interface IAddressDetails {}

export interface IJobServices {}
export interface IJobDetails {
  jobServices: IJobServices[];
  profitMargin: number;
  isFlatMargin: string;
  isResubmittingBid: boolean;
  jobStatus: string;
  jobBids: IJobBids[];
}

export interface ModifyQuoteDetails {
  jobId: string;
  jobName: string;
  jobType: string;
  customerId: string;
  customerEmail: string;
  customerName: string;
  isCustomerApprovalAvailable: boolean;
  subJobId: string;
  subJobBidId: string;
  jobCustodian: string;
  startDate: string;
  stopDate: string;
  address: IAddressDetails;
  jobVendors: IJobVendorDetails[];
  jobBids: IJobBids[];
  jobServices: IJobServices[];
  jobDetails: IJobDetails;
  bidSummaryResponse: IBidSummaryResponse;
}

export enum DataStatus {
  Active = 'A',
  Cancelled = 'X',
  Closed = 'C',
  Draft = 'D',
}

export enum StatusType {
  Awarded = 'Awarded',
  ClosedForBids = 'Closed for bids',
  Ongoing = 'Ongoing',
  OpenForBids = 'Open for bids',
}

enum JobStatusCheck {
  Status = 'completed-jobs',
  DateFormat = 'MM/DD/yyyy',
  NoDataYet = 'noData:noDataYet',
  NoDataComeBack = 'noData:comeBackLater',
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps & { style?: React.CSSProperties }) {
  const { children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}>
      {value === index && children}
    </div>
  );
}

const JobsView = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const { t } = useTranslation(['Dashboard', 'tableHeader', 'homePage', 'altTexts', 'calendar']);
  const [recurringJob, setRecurringJob] = useState<IJobs[]>([]);
  const [oneTimeJobs, setOneTimeJobs] = useState<IJobs[]>([]);
  const [completedJobs, setCompletedJobs] = useState<IJobs[]>([]);
  const location = useLocation();
  const [tabSelected, setTabSelected] = useState(
    location?.pathname.includes('onetime') ? 1 : location?.pathname.includes(JobStatusCheck.Status) ? 2 : 0
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const fromDate = '2023-01-01';

  const [sortBy, setSortBy] = useState('jobName');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');
  const [dialogueBoxOpen, setDialogueBoxOpen] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState('');
  const [deleteJobName, setDeleteJobName] = useState('');
  const [clickedRow, setClickedRow] = useState(null);
  const [customerId, setCustomerId] = useState<string>();
  const [jobType, setJobType] = useState('');

  const handleClickOpen = (): void => {
    setDialogueBoxOpen(true);
  };

  const fetchCompletedJobs = async (pageNumber?: number, sortBy?: any, sortOrder?: any) => {
    setIsLoading(true);
    let sortOrderString = sortOrder;
    if (sortOrder) {
      sortOrderString = SortOrderType.DESC;
    } else {
      if (sortBy) sortOrderString = SortOrderType.ASC;
    }

    const { customerId } = getStoredCustomerDetails();
    try {
      const allCompletedJobsResponse = await WorkOrdersApiService.getAllWorkOrders({
        customerId,
        status: WorkOrderStatus.Completed,
        pageNumber,
        sortBy,
        sortOrder: sortOrderString,
      });
      if (
        allCompletedJobsResponse.data &&
        !isEmpty(allCompletedJobsResponse.data) &&
        isEmpty(allCompletedJobsResponse.errors)
      ) {
        setTotalCount(allCompletedJobsResponse.metadata.totalCount);
        const updatedData = await Promise.all(
          allCompletedJobsResponse?.data.map(async (job: any) => {
            return {
              ...JSON.parse(job.jobBidSummary.jobDetails),
              ...job,
              facilityName: job.facilities[0].facilityName,
              numberOfServices: job.services.length,
            };
          })
        );
        setCompletedJobs(updatedData);
      } else if (!isEmpty(allCompletedJobsResponse.errors) && allCompletedJobsResponse?.errors[0]?.message) {
        setSnackbarMessage(allCompletedJobsResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('error occurred while fetching completed jobs data', error);
      setSnackbarMessage(error as string);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsLoading(false);
  };

  const fetchJobsView = useCallback(async (jobType: any, currentPage: any, sortBy?: any, sortOrder?: any) => {
    try {
      setIsLoading(true);
      setJobType(jobType);
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      setCustomerId(customerId);
      let sortOrderString = sortOrder;
      if (sortOrder) {
        sortOrderString = SortOrderType.DESC;
      } else {
        if (sortBy) sortOrderString = SortOrderType.ASC;
      }

      const allJobResponse = await JobViewApiService.jobView(
        customerId,
        userId,
        fromDate,
        jobType,
        currentPage,
        sortBy,
        sortOrderString
      );
      const allJobsData: any[] = [];
      const promises: Promise<any>[] = [];
      if (allJobResponse?.data?.data?.length) {
        allJobResponse.data.data.forEach((job: any, index: number) => {
          const promise = JobCreateApiService.jobSummaryV2(customerId, job.jobId, false).then((jobSummaryResponse) => {
            allJobsData[index] = {
              ...job,
              ...jobSummaryResponse.data,
              status: jobSummaryResponse.data?.dataStatus === 'Draft' ? 'Draft' : job.status,
            };
            return undefined;
          });

          promises.push(promise);
        });
      }

      await Promise.all(promises);
      setIsLoading(false);
      if (jobType === 'OneTime') {
        setOneTimeJobs(allJobsData);
        setTotalCount(allJobResponse?.data?.metadata?.totalCount);
      } else {
        setRecurringJob(allJobsData ?? []);
        setTotalCount(allJobResponse?.data?.metadata?.totalCount);
      }

      return [];
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const sortingJobs = (sortingObj: any) => {
    setSorting(sortingObj);
    if (sortingObj.length) {
      setNewSortOrder(sortingObj[0].id);
      setNewSort(sortingObj[0].desc);
      if (tabSelected === 2) {
        fetchCompletedJobs(currentPage, sortingObj[0].id, sortingObj[0].desc);
      } else {
        fetchJobsView(tabSelected === 1 ? JobType.OneTime : JobType.Recurring, 1, sortingObj[0].id, sortingObj[0].desc);
      }
    } else {
      if (tabSelected === 2) {
        fetchCompletedJobs(currentPage);
      } else {
        fetchJobsView(tabSelected === 1 ? JobType.OneTime : JobType.Recurring, 1);
      }
    }
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: number, sortBy?: any, sortOrder?: any) => {
      setTabSelected(newValue);
      window.history.pushState(
        null,
        'Peazy',
        `/jobs-view/${newValue === 0 ? 'recurring' : newValue === 1 ? 'onetime' : JobStatusCheck.Status}`
      );
      if (newValue === 1) {
        fetchJobsView('OneTime', 1, sortBy, sortOrder);
      } else if (newValue === 2) {
        fetchCompletedJobs(currentPage, sortBy, sortOrder);
      } else {
        fetchJobsView('Recurring', 1, sortBy, sortOrder);
      }
    },
    []
  );

  const myCustomSortingFn = () => {
    setSortBy(SortOrderType.DESC);
  };

  useEffect(() => {
    if (location?.state?.jobName) {
      setSnackbarMessage(`${location?.state?.jobName} Job successfully created.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
    setCurrentPage(1);
    setTotalCount(0);
    if (tabSelected === 1) {
      fetchJobsView('OneTime', 1);
    } else if (tabSelected === 2) {
      fetchCompletedJobs(currentPage);
    } else {
      fetchJobsView('Recurring', 1);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setTotalCount(0);
  }, [tabSelected]);

  useEffect(() => {
    setTabSelected(
      location?.pathname.includes('onetime') ? 1 : location?.pathname.includes(JobStatusCheck.Status) ? 2 : 0
    );
  }, [location.pathname]);

  // material UI Logic
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderJobLinkCell = (renderedCellValue: string | React.ReactNode | null | undefined, row: any) => {
    const { jobId } = row.original as unknown as IJobRow;
    const jobType = row.original.jobType;

    if (typeof renderedCellValue === 'string') {
      const jobs = jobType === 'OneTime' || jobType === 'Emergency' ? oneTimeJobs : recurringJob;

      return (
        <JobLink
          to={{ pathname: `/onetimejobdetails`, search: `?id=${jobId}` }}
          onClick={() =>
            localStorage.setItem(
              'locationState',
              JSON.stringify({
                facilityItem: {
                  ...jobs[row.index],
                  estimationType: 'estimateWithinPeazy',
                  executionType: 'self',
                },
                jobStatus: jobs[row.index].status,
                facility: {
                  ...jobs[row.index].facility,
                  address: jobs[row.index].address,
                  facilityType: jobs[row.index].facilityType,
                },
                job: { ...jobs[row.index], customerId },
              })
            )
          }
          state={{
            facilityItem: { ...jobs[row.index], estimationType: 'estimateWithinPeazy', executionType: 'self' },
            jobStatus: jobs[row.index]?.status,
            facility: {
              ...jobs[row.index]?.facility,
              address: jobs[row.index]?.address,
              facilityType: jobs[row.index]?.facilityType,
            },
            job: { ...jobs[row.index], customerId },
          }}>
          {renderedCellValue}
        </JobLink>
      );
    }

    return '-';
  };

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const formatDateCell = (cell: any) => {
    const cellValue = cell.getValue();
    if (cellValue) {
      return formatDate(cellValue, 'MM/DD/YYYY');
    }
    return '-';
  };

  const renderStatusCell = (renderedCellValue: string | React.ReactNode | undefined) => {
    const selectedColor = theme.palette.text.secondary;

    if (typeof renderedCellValue === 'string') {
      return <span style={{ color: selectedColor }}>{DATA_STATUS[renderedCellValue as keyof typeof DATA_STATUS]}</span>;
    }

    // If renderedCellValue is a ReactNode, return it
    if (isValidElement(renderedCellValue)) {
      return renderedCellValue;
    }

    return '-';
  };

  const renderActionsCell = (row: any) => {
    const { jobType } = row.original as unknown as IJobRow;
    return (
      <ActionsColumn>
        <IconButton
          onClick={(e) => {
            handleClick(e);
            setClickedRow(row.index);
            setRowInfo(row.original);
          }}
          key={row.index}
          sx={{
            backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }}
          size="large"
          aria-haspopup="true"
          {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
          <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
        </IconButton>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking
            to={{ pathname: `/onetimejobdetails`, search: `?id=${rowInfo.jobId}` }}
            state={{
              facilityItem: { ...rowInfo, estimationType: 'estimateWithinPeazy', executionType: 'self' },
              jobStatus: rowInfo.status,
              facility: {
                ...rowInfo.facility,
                address: rowInfo.address,
                facilityType: rowInfo.facilityType,
              },
              job: { ...rowInfo, customerId },
            }}
            onClick={() =>
              localStorage.setItem(
                'locationState',
                JSON.stringify({
                  facilityItem: { ...rowInfo, estimationType: 'estimateWithinPeazy', executionType: 'self' },
                  jobStatus: rowInfo.status,
                  facility: {
                    ...rowInfo.facility,
                    address: rowInfo.address,
                    facilityType: rowInfo.facilityType,
                  },
                  job: { ...rowInfo, customerId },
                })
              )
            }>
            <Menus>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> {t('dashboard:viewDetails')}
            </Menus>
          </Linking>
          {((jobType as JobType) === JobType.Emergency || (jobType as JobType) === JobType.OneTime) &&
            rowInfo.status === JobStatus.Draft && (
              <Linking
                to="/createOneTimeJob"
                state={{
                  jobRecord: { ...rowInfo, estimationType: 'estimateWithinPeazy', executionType: 'self' },
                  isJobCreation: false,
                  mediaFileIds: [],
                }}>
                <Menus>
                  <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} /> {t('dashboard:modify')}
                </Menus>
              </Linking>
            )}
          {((jobType as JobType) === JobType.Emergency || (jobType as JobType) === JobType.OneTime) &&
            rowInfo.status === JobStatus.OpenToBid && (
              <Linking
                to={{ pathname: `/onetimejobdetails`, search: `?id=${rowInfo.jobId}` }}
                state={{
                  facilityItem: { ...rowInfo, estimationType: 'estimateWithinPeazy', executionType: 'self' },
                  jobStatus: rowInfo.status,
                  facility: {
                    ...rowInfo.facility,
                    address: rowInfo.address,
                    facilityType: rowInfo.facilityType,
                  },
                  job: { ...rowInfo, customerId },
                  isModifyJobDetails: true,
                }}
                onClick={() => {
                  localStorage.setItem(
                    'locationState',
                    JSON.stringify({
                      facilityItem: { ...rowInfo, estimationType: 'estimateWithinPeazy', executionType: 'self' },
                      jobStatus: rowInfo.status,
                      facility: {
                        ...rowInfo.facility,
                        address: rowInfo.address,
                        facilityType: rowInfo.facilityType,
                      },
                      job: { ...rowInfo, customerId },
                      isModifyJobDetails: true,
                    })
                  );
                }}>
                <Menus>
                  <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} /> {t('dashboard:modify')}
                </Menus>
              </Linking>
            )}
          {(rowInfo.status === JobStatuses.Draft ||
            rowInfo.status === JobStatuses.OpenToBid ||
            rowInfo.status === JobStatuses.PendingEstimation ||
            rowInfo.status === JobStatuses.PendingCustomerApproval ||
            rowInfo.status === JobStatuses.ClosedForBid) && (
            <Menus
              onClick={() => {
                setDeleteJobId(rowInfo.jobId);
                setDeleteJobName(rowInfo.jobName);
                handleClickOpen();
              }}>
              <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} /> {t('dashboard:delete')}
            </Menus>
          )}
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: tabSelected === 2 ? 'name' : 'jobName',
        header: t('tableHeader:jobName'),
        enableSorting: true,
        enableColumnFilter: tabSelected === 2 ? false : true,
        muiEditTextFieldProps: {
          required: true,
          type: 'number',
          variant: 'outlined',
        },
        size: 210,
        Cell: ({ renderedCellValue, row }: any) => (
          <div style={{ color: theme.palette.primary.dark }}>{renderJobLinkCell(renderedCellValue, row)}</div>
        ),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'facilityName',
        header: t('tableHeader:facility'),
        enableSorting: true,
        enableColumnFilter: tabSelected === 2 ? false : true,
        size: 234,
        manualSorting: tabSelected === 2 ? false : true,
        state: { sortBy },
        onSortingChange: setSortBy,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
      },
      {
        accessorKey: 'jobStartDate',
        header: t('tableHeader:jobDate'),
        enableSorting: true,
        enableColumnFilter: tabSelected === 2 ? false : true,
        size: 178,
        filterVariant: 'date',
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = formatDate(row.getValue('jobStartDate'), 'MM/DD/yyyy');
          const filteredValue = formatDate(filterValue.$d, 'MM/DD/yyyy');
          return formattedValue.includes(filteredValue);
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        accessorKey: 'numberOfServices',
        header: t('tableHeader:NoOfServices'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 178,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: tabSelected === 2 ? false : true,
      },
      {
        accessorKey: 'status',
        header: t('tableHeader:Status'),
        enableSorting: tabSelected === 2 ? false : true,
        enableColumnFilter: false,
        enableColumnActions: tabSelected === 2 ? false : true,
        sortingFn: 'alphanumericCaseSensitive',
        size: 142,
        Cell: ({ renderedCellValue }) => renderStatusCell(renderedCellValue),
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [handleClose]
  );

  const recurringJobColumn = useMemo(() => {
    const onetimeJobColumn = columns;
    (onetimeJobColumn[2].header = t('calendar:startDate')),
      onetimeJobColumn.splice(3, 0, {
        accessorKey: 'jobEndDate',
        header: t('calendar:endDate'),
        enableSorting: true,
        enableColumnFilter: tabSelected === 2 ? false : true,
        size: 178,
        filterVariant: 'date',
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = formatDate(row.getValue('jobEndDate'), 'MM/DD/yyyy');
          const filteredValue = formatDate(filterValue.$d, 'MM/DD/yyyy');
          return formattedValue.includes(filteredValue);
        },
        Cell: ({ cell }: any) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      });
    return onetimeJobColumn;
  }, [columns]);

  return (
    <>
      <PageTitle title={t('dashboard:jobs')} />
      <CustomerContainer>
        <TabSection sx={{ height: '100%' }}>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              <TabTitle label={t('dashboard:recurring')} {...a11yProps(0)} />
              <TabTitle label={t('dashboard:oneTime')} {...a11yProps(1)} />
              <TabTitle label={t('dashboard:completedJobs')} {...a11yProps(2)} />
            </Tabs>
          </TabsWrapper>
          <TabPanel value={tabSelected} index={0} style={{ height: '100%' }}>
            <DataGrid
              columns={recurringJobColumn}
              data={recurringJob}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
              errorMessageTitle={t('noData:noDataYet')}
              errorMessageDescription={t('noData:comeBackLater')}
            />
            {!isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {!isLoading && totalCount > 10 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <StyledPagination
                      count={Math.ceil(totalCount / itemsPerPage)}
                      page={currentPage}
                      disabled={isLoading}
                      onChange={(event, value) => {
                        handleChange(event, value);
                        if (newSortOrder) {
                          fetchJobsView('Recurring', value, newSortOrder, newSort);
                        } else {
                          fetchJobsView('Recurring', value, '', '');
                        }
                      }}
                      color="primary"
                    />
                  </Stack>
                )}
                {totalCount > 0 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <Typography>{`Total items: ${totalCount}`}</Typography>
                  </Stack>
                )}
              </Box>
            )}
          </TabPanel>
          <TabPanel value={tabSelected} index={1} style={{ height: '100%' }}>
            <DataGrid
              columns={columns}
              data={oneTimeJobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
              errorMessageTitle={t('noData:noDataYet')}
              errorMessageDescription={t('noData:comeBackLater')}
            />
            {!isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {!isLoading && totalCount > 10 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <StyledPagination
                      count={Math.ceil(totalCount / itemsPerPage)}
                      page={currentPage}
                      disabled={isLoading}
                      onChange={(event, value) => {
                        handleChange(event, value);
                        if (newSortOrder) {
                          fetchJobsView('OneTime', value, newSortOrder, newSort);
                        } else {
                          fetchJobsView('OneTime', value, '', '');
                        }
                      }}
                      color="primary"
                    />
                  </Stack>
                )}
                {totalCount > 0 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <Typography>{`Total items: ${totalCount}`}</Typography>
                  </Stack>
                )}
              </Box>
            )}
          </TabPanel>
          <TabPanel value={tabSelected} index={2} style={{ height: '100%' }}>
            <DataGrid
              columns={columns}
              data={completedJobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
              errorMessageTitle={t('noData:noDataYet')}
              errorMessageDescription={t('noData:comeBackLater')}
            />
            {!isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {!isLoading && totalCount > 10 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <StyledPagination
                      count={Math.ceil(totalCount / itemsPerPage)}
                      page={currentPage}
                      disabled={isLoading}
                      onChange={(event, value) => {
                        handleChange(event, value);
                        fetchCompletedJobs(value);
                        if (newSortOrder) {
                          fetchCompletedJobs(value, newSortOrder, newSort);
                        } else {
                          fetchCompletedJobs(value, '', '');
                        }
                      }}
                      color="primary"
                    />
                  </Stack>
                )}
                {totalCount > 0 && (
                  <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                    <Typography>{`Total items: ${totalCount}`}</Typography>
                  </Stack>
                )}
              </Box>
            )}
          </TabPanel>
        </TabSection>
        <SnackBarWrapper>
          {tabSelected === 1 && (
            <Link to="/createOneTimeJob">
              <Snackbar
                open
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                action={
                  <Button
                    icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:addIcon')} />}
                    type={ButtonType.Primary}
                    label={t('homePage:oneTimeJob')}
                  />
                }
              />
            </Link>
          )}
        </SnackBarWrapper>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        <DeleteJobs
          isApproveWindowOpen={dialogueBoxOpen}
          handleCloseApproveWindow={() => setDialogueBoxOpen(false)}
          deleteJobId={deleteJobId}
          deleteJobName={deleteJobName}
          fetchJobsView={fetchJobsView}
          jobType={jobType}
          fetchData={() => {}}></DeleteJobs>
      </CustomerContainer>
    </>
  );
};

export default JobsView;
