/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { useForm, useWatch } from 'react-hook-form';

import { DateRangeSelector } from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection.Model';
import { Labels } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { getStoredCustomerDetails, getAuthDetails, JobDetails, getAuthData } from '../../../../Shared/Utilities/utils';
import { TaskData } from '../WorkTasks/WorkTasks';
import { IJobs } from '../JobView/JobsView';
import AnalyticsApiService, { UserType } from '../../../../Shared/Services/AnalyticsService';
import {
  DateRangeOptionType,
  DefaultOptions,
  FilterName,
  FormFieldType,
  HeaderLegalName,
  LegalName,
  NumberOfRowsOptions,
} from '../../../../Shared/Constants/App';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { BooleanType } from '../../../../API';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import JobCreateApiService from '../../Services/JobCreateService';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import DateRangeSelection from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection';
import CustomDropDown from '../../../../Shared/Components/Common/CustomDropDown/CustomDropdown';

import { Icons } from '../../../../Shared/Constants/Icons';

import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { DropDown, SelectInput } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer.styles';
import { ReadMore } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { TitleWrapper, TableWrapper } from './JobsListView.styles';
import { JobsTitle } from '../IssuesRaised/JobsIssuesRaised.styles';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';

export interface IJobOverview {
  facility: string;
  facilityId: string;
  jobName: string;
  approvedByCustomer?: string | number;
  totalTasks?: number;
  status: string;
  totalCompleted: string;
  totalInProgress: string;
  totalApprovedBySupervisor: string;
  totalApprovedByCustomer: string;
  jobId?: string;
  sortingFn?: any;
  executionType?: string;
  customerId?: string;
}

export type FilterItem = {
  id: string;
  value: string;
};

export type StatusItem = {
  label: string;
  value: string;
};

export type JobTypeItem = {
  label: string;
  value: string;
};

const JobsListView = (): JSX.Element => {
  const { t } = useTranslation(['Dashboard', 'homePage', 'calendar']);
  const [jobs, setJobs] = useState<IJobOverview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>(false);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [isValueOpen, setValueOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedAssociateId, setSelectedAssociateId] = useState<string>();
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([
    { id: 'workDayStatus', value: 'All' },
    { id: 'jobType', value: 'All' },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeSelector>({
    fromDate: null,
    toDate: null,
  });
  const [associatesAndVendors, setAssociatesAndVendors] = useState<{ id: string; name: string }[]>([]);
  const [selectedAssociateVendor, setSelectedAssociateVendor] = useState<string>(DefaultOptions.All);
  const [isAssociateAndVendorLoading, setIsAssociateAndVendorLoading] = useState(false);

  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const defaultDateRangeOption = '1';
  const defaultRoleBasedOption = DefaultOptions.All;
  const storedAuthDetails = getAuthDetails();
  const { isDistrictManager } = getAuthData() || {};
  const customRole = 'custom:Role';
  const role = storedAuthDetails[customRole] as UserType;

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const { control } = useForm({
    defaultValues: {
      specificDate: selectedDate ?? new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const dateRangeOptions = [
    { id: 1, label: 'Today', value: '1', type: DateRangeOptionType.Days },
    { id: 2, label: 'Last 7 Days', value: '7', type: DateRangeOptionType.Days },
    { id: 3, label: 'Last 30 Days', value: '30', type: DateRangeOptionType.Days },
    { id: 4, label: 'Custom Date Range', value: 'custom', type: DateRangeOptionType.CustomDateRange },
  ];

  const shiftStatuses: StatusItem[] = [
    { label: 'All', value: '' },
    { label: 'Scheduled', value: 'A' },
    { label: 'In Progress', value: 'P' },
    { label: 'Completed', value: 'S' },
    { label: 'Missed', value: 'N' },
    { label: 'Incomplete', value: 'I' },
  ];
  const jobTypes: JobTypeItem[] = [
    { label: 'All', value: '' },
    { label: 'Recurring', value: 'Recurring' },
    { label: 'One time', value: 'OneTime' },
    { label: 'Emergency', value: 'Emergency' },
  ];

  const filters = [
    {
      title: storedAuthDetails[customRole] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:associates'),
      type: FormFieldType.DropDown,
      name: FilterName.AssociateAndVendor,
      options: associatesAndVendors.map((associateAndVendor) => ({
        label: associateAndVendor.name,
        value: associateAndVendor.id,
      })),
    },
  ];

  const handleDateRangeChange = (dateRange: DateRangeSelector) => {
    setSelectedDateRange({
      fromDate: dateRange.fromDate || new Date(),
      toDate: dateRange.toDate || new Date(),
    });
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = Number(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const handlePaginationChange = (event: any, value: number) => {
    handleChange(event, value);
    setCurrentPage(value);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      value,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const fetchAssociatesAndVendors = async () => {
    setIsAssociateAndVendorLoading(true);
    try {
      let associateVendorData = [];
      if (role === UserType.Vendor) {
        const customerDetails = getStoredCustomerDetails();
        const vendorId = customerDetails?.customerId;
        const response = await CustomerApiService.getAllUsers(vendorId, '', 0, 10, BooleanType.True, false);
        associateVendorData = response.data.map((associate: { userId: any; username: any }) => ({
          id: associate.userId,
          name: associate.username,
        }));
      } else if (role === UserType.Customer) {
        const response = await JobCreateApiService.getAllVendorsOnARK('', 'A', {});
        associateVendorData = response.data.map((vendor: { vendorId: any; vendorCompanyName: any }) => ({
          id: vendor.vendorId,
          name: vendor.vendorCompanyName,
        }));
      }
      setAssociatesAndVendors([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...associateVendorData]);
    } catch (error) {
      console.error('Error fetching users', error);
    } finally {
      setIsAssociateAndVendorLoading(false);
    }
  };

  const handleAssociateVendorChanges = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedAssociateVendor(value);
    handleAssociateVendorChange(value);
  };

  const handleAssociateVendorChange = (selectedAssociateId: string) => {
    setSelectedAssociateId(selectedAssociateId);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const getSortOrderString = (sortBy?: any, sortOrder?: any) => {
    let sortOrderString = sortOrder;
    if (sortOrder) {
      sortOrderString = 'desc';
    } else {
      if (sortBy) sortOrderString = 'asc';
    }
    return sortOrderString;
  };

  const fetchData = async (
    fromDate: Date,
    toDate: Date,
    currentPage: any,
    selectedAssociateId?: string,
    sortBy?: any,
    sortOrder?: any,
    columnFilters: FilterItem[] = []
  ) => {
    try {
      setIsLoading(true);
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const sortOrderString = getSortOrderString(sortBy, sortOrder);
      selectedAssociateId = selectedAssociateId ?? userId;

      const updatedColumnFilters = columnFilters.map((filter) => {
        if (filter.id === 'customerLegalName') {
          return {
            ...filter,
            id: 'customerName',
          };
        }
        if (filter.id === 'vendorLegalName') {
          return {
            ...filter,
            id: 'vendorName',
          };
        }
        return filter;
      });

      const queryParams = updatedColumnFilters
        .map((filter) => {
          const statusMatch = shiftStatuses.find((s) => s.label.toLowerCase() === filter.value.toLowerCase());
          const value = statusMatch ? statusMatch.value : filter.value;
          return `${encodeURIComponent(filter.id)}=${encodeURIComponent(value)}`;
        })
        .join('&');

      const allIdsResponse = await AnalyticsApiService.paginatedTaskSummary(
        customerId,
        fromDate,
        toDate,
        storedAuthDetails[customRole] === 'Customer' ? UserType.Customer : UserType.Vendor,
        userId,
        currentPage,
        itemsPerPage,
        sortBy,
        sortOrderString,
        queryParams,
        selectedAssociateId
      );

      if (allIdsResponse?.errors?.length) {
        // Handle errors if needed
        // setIsError(true);
      } else if (allIdsResponse?.data?.data?.length > 0) {
        const modifiedJobs = await processJobsData(allIdsResponse.data.data, customerId);
        setJobs(modifiedJobs);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      } else {
        setJobs([]);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      }
    } catch (e) {
      console.log('Error', e);
      // Handle error state
    } finally {
      setIsLoading(false);
    }
  };

  const processJobsData = async (jobsData: any[], customerId: string) => {
    return await Promise.all(
      jobsData.map(async (item: any) => {
        const modifiedJob: any = { ...item };
        const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, item.jobId);
        modifiedJob.customerId = jobSummaryResponse?.data?.customerId;
        modifiedJob.executionType =
          jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === 'SubVendor'
            ? JobDetails.SubVendor
            : JobDetails.Self;
        return modifiedJob;
      })
    );
  };

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
  };

  const filterJobs = (filtersObj: FilterItem[]): void => {
    setColumnFilters(filtersObj);
    if (filtersObj?.length) {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        newSortOrder,
        newSort,
        filtersObj
      );
    } else {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        newSortOrder,
        newSort,
        []
      );
    }
  };

  const sortingJobs = (sortingObj: any): void => {
    setSorting(sortingObj);
    if (sortingObj.length) {
      setNewSortOrder(sortingObj[0].id);
      setNewSort(sortingObj[0].desc);
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        sortingObj[0].id,
        sortingObj[0].desc
      );
    } else {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        '',
        newSort,
        columnFilters
      );
    }
  };

  useEffect(() => {
    const fromDate = selectedDateRange?.fromDate ?? new Date();
    let toDate = selectedDateRange?.toDate ?? new Date();
    if (
      selectedDateRange?.fromDate &&
      selectedDateRange?.toDate &&
      selectedDateRange.fromDate.getTime() === selectedDateRange.toDate.getTime()
    ) {
      toDate = new Date();
    }

    void fetchData(fromDate, toDate, currentPage, selectedAssociateId, newSortOrder, newSort, columnFilters);
  }, [selectedDateRange, itemsPerPage, currentPage, newSortOrder, newSort, columnFilters]);

  useEffect(() => {
    void fetchAssociatesAndVendors();
  }, [role]);

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'facilityName',
        header: 'Facility',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: true,
        size: 300,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableHiding: false,
        enableColumnActions: true,
      },
      {
        accessorKey:
          storedAuthDetails[customRole] === UserType.Customer ? LegalName.vendorLegalName : LegalName.customerLegalName,
        header:
          storedAuthDetails[customRole] === UserType.Customer
            ? HeaderLegalName.VendorName
            : HeaderLegalName.CustomerName,
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: true,
        size: 300,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableHiding: false,
        enableColumnActions: true,
      },
      {
        accessorKey: 'workOrderName',
        header: 'Job name',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: true,
      },
      {
        accessorKey: 'scheduleName',
        header: 'Schedule Name',
        enableSorting: false,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: false,
      },
      {
        accessorKey: 'jobType',
        header: 'Job type',
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        filterSelectOptions: jobTypes.map((jobTypeItem) => jobTypeItem.label),
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => jobTypes.find((jt) => jt.value === renderedCellValue)?.label ?? '-',
        enableColumnActions: true,
        filterVariant: 'select',
      },
      {
        accessorKey: 'workDayStatus',
        header: 'Job status',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        filterSelectOptions: shiftStatuses.map((shiftStatusItem) => shiftStatusItem.label),
        enableColumnFilter: true,
        enableColumnActions: true,
        size: 162,
        filterVariant: 'select',
      },
      {
        header: 'Tasks done',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;

          return `${approvedByCustomer}/${totalTasks}`;
        },
        enableColumnActions: false,
      },
      {
        header: 'Inspection score',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;
          const perc =
            approvedByCustomer && totalTasks
              ? `${((Number(approvedByCustomer) / Number(totalTasks)) * 100).toFixed(0)}%`
              : '0.00%';
          return `${perc}`;
        },
        enableColumnActions: false,
      },
      {
        header: 'Actions',
        enableColumnActions: false,
        enablePinning: false,
        size: 162,
        Cell: ({ cell }) => {
          return (
            <ReadMore
              to="/job-detail/worktasks"
              state={{
                facilityItem: cell.row.original,
                jobId: getParentJobId(cell.row.original),
                executionType: cell.row.original?.executionType,
                customerId: cell.row.original?.customerId,
                selectedDate: specificDate,
              }}>
              {t('dashboard:viewDetails')}
            </ReadMore>
          );
        },
      },
    ],
    [specificDate]
  );

  const getParentJobId = (data: any) => {
    if (isDistrictManager) {
      return data.parentJobId ?? data.jobId;
    } else {
      return data.jobId;
    }
  };

  return (
    <>
      <PageTitle title={t('homePage:listView')} />
      <CustomerContainer>
        <TitleWrapper>
          <JobsTitle>{t('homePage:jobOverView')}</JobsTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <DateRangeSelection
              onDateRangeChange={handleDateRangeChange}
              defaultOption={defaultDateRangeOption}
              options={dateRangeOptions}
            />
            {storedAuthDetails[customRole] === UserType.Vendor && (
              <CustomDropDown
                title={filters.find((filter) => filter.name === FilterName.AssociateAndVendor)?.title}
                options={
                  filters.find((filter: { name: string }) => filter.name === FilterName.AssociateAndVendor)?.options ||
                  []
                }
                selectedValue={selectedAssociateVendor}
                onChange={handleAssociateVendorChanges}
                isLoading={isAssociateAndVendorLoading}
                defaultOption={defaultRoleBasedOption}
              />
            )}
          </Box>
        </TitleWrapper>
        <TableWrapper>
          <>
            <DataGrid
              columns={columns}
              data={jobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              filter={filterJobs}
              errorMessageTitle={''}
              errorMessageDescription={t('homePage:shiftNotFound')}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
            />
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              {!isLoading && totalCount > 10 && (
                <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                  <StyledPagination
                    count={Math.ceil(totalCount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    color="primary"
                  />
                </Stack>
              )}
              {!isLoading && (
                <Stack
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    marginTop: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '3rem',
                  }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '1rem',
                    }}>
                    {t('dashboard:totalItems')}: {totalCount}
                  </Typography>
                  {totalCount > 10 && (
                    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      <Typography>{t('dashboard:recordsPerPage')}:</Typography>
                      <DropDown>
                        <Box onClick={toggleValue}>
                          <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            label="Records"
                            input={<SelectInput />}
                            IconComponent={() => (
                              <img
                                src={Icons.DropdownIcon}
                                style={{
                                  transform: isValueOpen ? Labels.Rotate : 'none',
                                  position: 'absolute',
                                  right: '0.75rem',
                                }}
                              />
                            )}
                            open={isValueOpen}
                            onClose={toggleValue}
                            onOpen={toggleValue}>
                            {NumberOfRowsOptions.map((dataItem) => (
                              <Options key={dataItem.id} value={dataItem.value}>
                                {dataItem.label}
                              </Options>
                            ))}
                          </Select>
                        </Box>
                      </DropDown>
                    </Box>
                  )}
                </Stack>
              )}
            </Box>
          </>
        </TableWrapper>
      </CustomerContainer>
    </>
  );
};

export default JobsListView;
