/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconButton, Snackbar, Stack } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import { FacilityInterface } from '../../Models/Company.Model';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { fetchFacilityTypes } from '../../../Customer/Components/ViewFacilityDetails/FacilityType';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';

import { Icons } from '../../../../Shared/Constants/Icons';

import theme from '../../../../Shared/Themes/theme';

import { SnackBarWrapper } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import {
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';

const Facilities = (props: any): JSX.Element => {
  const [facilityData, setFacilityData] = useState<FacilityInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('buildingName');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');
  const [clickedRow, setClickedRow] = useState(null);
  const [rowInfo, setRowInfo] = useState<any>({});

  const itemsPerPage = 10;
  const { t } = useTranslation(['Dashboard', 'tableHeader', 'homePage', 'altTexts', 'calendar']);

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
    void getFacilityData(value);
  };

  const getFacilityData = useCallback(async (pageNumber?: number, sortBy?: any, sortOrder?: any) => {
    try {
      setIsLoading(true);
      const { customerId = '' } = getStoredCustomerDetails() || {};
      let sortOrderString = sortOrder;
      if (sortOrder) {
        sortOrderString = 'desc';
      } else {
        if (sortBy) sortOrderString = 'asc';
      }
      const facilitiesResponse: any = await FacilityApiService.getAllFacilities(
        customerId,
        pageNumber,
        itemsPerPage,
        sortBy,
        sortOrderString
      );
      const requireFacilityData = await Promise.all(
        facilitiesResponse?.data.map(
          async ({
            buildingName,
            mdFacilityType,
            totalBusiness,
            ongoingJobs,
            address,
            facilityId,
          }: FacilityInterface) => {
            const fullAddress = address.addressName
              ? address.addressLine2
              : `${address.addressLine1 ?? ''} ${address.addressLine2 ?? ''} ${address.city ?? ''} ${address.state ?? ''} ${address.postalCode ?? ''} ${address.county ?? ''}`;

            const facilityTypeName = await fetchFacilityTypes(mdFacilityType);

            return {
              buildingName,
              mdFacilityType: facilityTypeName,
              totalBusiness,
              ongoingJobs,
              fullAddress,
              facilityId,
            };
          }
        )
      );

      setFacilityData([...requireFacilityData]);
      setTotalCount(facilitiesResponse?.metaData?.totalCount);
    } catch (e) {
      console.error('Error', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    void getFacilityData(currentPage);
  }, []);

  // material UI Logic
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const renderActionsCell = (row: any): JSX.Element => {
    return (
      <ActionsColumn>
        <IconButton
          onClick={(e) => {
            handleClick(e);
            setClickedRow(row.index);
            setRowInfo(row.original);
          }}
          key={row.index}
          sx={{
            backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }}
          size="large"
          aria-haspopup="true"
          {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
          <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
        </IconButton>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking to={{ pathname: `/view-facility-details`, search: `?id=${rowInfo.facilityId}` }} state={{ rowInfo }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> {t('dashboard:viewDetails')}
            </Menus>
          </Linking>
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const FacilityColumns = useMemo<
    MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | FacilityInterface | IProfileCardProps>[]
  >(
    () => [
      {
        accessorKey: 'buildingName',
        header: t('tableHeader:name'),
        enableSorting: true,
        enableColumnFilter: true,
        size: 234,
        manualSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
      },
      {
        accessorKey: 'fullAddress',
        header: t('tableHeader:location'),
        enableSorting: false,
        enableColumnFilter: true,
        size: 234,
        manualSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
        enableColumnActions: true,
        sortingFn: 'alphanumericCaseSensitive',
      },
      {
        accessorKey: 'mdFacilityType',
        header: t('tableHeader:facilityType'),
        enableSorting: true,
        enableColumnFilter: true,
        size: 234,
        manualSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
        enableColumnActions: true,
        sortingFn: 'alphanumericCaseSensitive',
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [handleClose]
  );
  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const handleSort = async (sortingObj: any): Promise<void> => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        setNewSortOrder(sortingObj[0].id);
        setNewSort(sortingObj[0].desc);
        await getFacilityData(currentPage, sortingObj[0].id, sortingObj[0].desc);
      } else {
        await getFacilityData(currentPage);
      }
    } catch (error) {
      console.error(`${t('messages:errorFetchingFacilityData')}`, error);
    }
  };

  const handleSortWrapper = (sortingObj: any) => {
    void handleSort(sortingObj);
  };

  return (
    <>
      <>
        <DataGrid
          columns={FacilityColumns}
          data={facilityData}
          enableRowSelect={false}
          enableColumnPinning={false}
          loader={isLoading}
          sortingObj={sorting}
          sort={handleSortWrapper}
          sortingFns={{
            myCustomSortingFn: myCustomSortingFn,
          }}
          errorMessageTitle={t('noData:noFacilitiesYet')}
          errorMessageDescription={t('noData:FacilityComeBack')}
        />
        {!isLoading && totalCount > 10 && (
          <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
            <StyledPagination
              count={Math.ceil(totalCount / itemsPerPage)}
              page={currentPage}
              onChange={(event, value) => {
                handleChange(event, value);
                if (newSortOrder) {
                  void getFacilityData(value, newSortOrder, newSort);
                } else {
                  void getFacilityData(value, '', '');
                }
              }}
              color="primary"
            />
          </Stack>
        )}
      </>
      <SnackBarWrapper>
        <Link to="/add-facility">
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
              <Button
                icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:addIcon')} />}
                type={ButtonType.Primary}
                label={t('homePage:addFacility')}
              />
            }
          />
        </Link>
      </SnackBarWrapper>
    </>
  );
};

export default Facilities;
