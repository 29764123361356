/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

// import { MRT_ColumnDef } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { formatDateCell } from '../../Utilities/Jobs.Utils';
import moment from 'moment';

import theme from '../../../../Shared/Themes/theme';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobs } from '../../../Jobs/Models/JobsView.Model';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { ExtendedMRT_ColumnDef, MarketplaceTabs } from '../../Models/Marketplace.model';

import { MediaContainer, MediaText } from '../CreateBid.Style';
import { Box } from '@mui/material';
import PhotosIcon from '../../../../assets/images/ic_photos.svg';
import VideoIcon from '../../../../assets/images/ic_video.svg';
import { MediaType } from '../../../../Shared/Constants/App';
import { useState } from 'react';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';

interface jobAttachments {
  photos: any;
  videos: any;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const useColumns = (type: MarketplaceTabs) => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [mediaType, setMediaType] = useState<MediaType>(MediaType.Photo);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const renderCell = (renderedCellValue: React.ReactNode) => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const renderPercentageMatchCell = (renderedCellValue: React.ReactNode) => {
    if (!renderedCellValue) {
      return 0;
    }
    const numericalValue = parseFloat(String(renderedCellValue)) * 100;
    if (String(numericalValue).includes('.')) {
      return numericalValue.toFixed(2);
    } else {
      return numericalValue;
    }
  };

  const renderFinalQuoteCell = (renderedCellValue: React.ReactNode) => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return `$${parseFloat(String(renderedCellValue)).toFixed(2)}`;
    }
    return '-';
  };

  const openMediaPopup = (type: MediaType, media: any) => {
    if (media.length) {
      setPopupOpen(true);
      setMediaType(type);
      if (type === MediaType.Photo) {
        setImages(media);
      } else {
        setVideos(media);
      }
    }
  };

  const closeMediaPopup = () => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const showMedia = (direction: 'previous' | 'next') => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === MediaType.Photo ? images : videos;
      return direction === 'previous'
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  const renderPhotosAndVideosCell = (renderedCellValue: jobAttachments) => {
    const images = renderedCellValue?.photos;
    const videos = renderedCellValue?.videos;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: '0.5rem',
        }}>
        <MediaContainer onClick={() => openMediaPopup(MediaType.Photo, images)}>
          <MediaText>{images?.length}</MediaText>
          <img src={PhotosIcon} alt={t('altTexts:galleryIcon')} />
        </MediaContainer>
        <MediaContainer onClick={() => openMediaPopup(MediaType.Video, videos)}>
          <MediaText>{videos?.length}</MediaText>
          <img src={VideoIcon} alt={t('altTexts:videoIcon')} />
        </MediaContainer>
      </Box>
    );
  };

  const columns: ExtendedMRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[] = [
    {
      accessorKey: 'jobName',
      header: t('tableHeader:jobName'),
      enableSorting: type !== MarketplaceTabs.RecommendedJobs,
      enableColumnFilter: type !== MarketplaceTabs.RecommendedJobs,
      size: 180,
      Cell: ({ renderedCellValue }) => (
        <div style={{ color: theme.palette.primary.dark }}>{renderCell(renderedCellValue)}</div>
      ),
      enableColumnActions: false,
      sortingFn: 'alphanumericCaseSensitive',
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'BidsInProgress', 'AllJobs', 'NotInterestedJobs', 'SubmittedBids'],
    },
    {
      accessorKey: 'facilityAddress',
      header: t('tableHeader:location'),
      enableSorting: type !== MarketplaceTabs.RecommendedJobs,
      enableColumnFilter: type !== MarketplaceTabs.RecommendedJobs,
      size: 180,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      sortingFn: 'alphanumericCaseSensitive',
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'BidsInProgress', 'AllJobs', 'NotInterestedJobs', 'SubmittedBids'],
    },
    {
      accessorKey: 'facilityType',
      header: t('tableHeader:facilityType'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 170,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      sortingFn: 'alphanumericCaseSensitive',
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'createdOn',
      header: t('tableHeader:createdOn'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      filterFn: (row, _columnIds, filterValue) => {
        const formattedValue = moment(row.getValue('createdOn')).format('MM / DD / yyyy');
        return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => formatDateCell(cell),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'BidsInProgress', 'AllJobs', 'NotInterestedJobs', 'SubmittedBids'],
    },
    {
      accessorKey: 'area',
      header: t('tableHeader:totalArea'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 150,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'BidsInProgress', 'AllJobs', 'NotInterestedJobs', 'SubmittedBids'],
    },
    {
      accessorKey: 'bidSubmissionDate',
      header: t('tableHeader:bidSubmissionDate'),
      enableSorting: false,
      enableColumnFilter: true,
      size: 170,
      Cell: ({ cell }) => formatDateCell(cell),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['SubmittedBids'],
    },
    {
      accessorKey: 'mdServiceName',
      header: t('tableHeader:serviceCategory'),
      enableSorting: false,
      enableColumnFilter: type !== MarketplaceTabs.RecommendedJobs,
      size: 150,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'BidsInProgress', 'AllJobs', 'NotInterestedJobs', 'SubmittedBids'],
    },
    {
      accessorKey: 'duration',
      header: t('tableHeader:duration'),
      enableSorting: false,
      enableColumnFilter: false,
      enableColumnActions: false,
      size: 142,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'volume',
      header: t('tableHeader:volume'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 150,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'percentageMatch',
      header: t('tableHeader:matchPercentage'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ renderedCellValue }) => renderPercentageMatchCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'noOfDaysLeft',
      header: t('tableHeader:daysLeft'),
      enableSorting: false,
      enableColumnFilter: type !== MarketplaceTabs.RecommendedJobs,
      size: 150,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'photosAndVideos',
      header: t('tableHeader:photosAndVideos'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ renderedCellValue }) => renderPhotosAndVideosCell(renderedCellValue as unknown as jobAttachments),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'finalQuote',
      header: t('tableHeader:finalQuote'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 178,
      Cell: ({ renderedCellValue }) => renderFinalQuoteCell(renderedCellValue),
      enableColumnActions: true,
      enableTabs: ['SubmittedBids'],
    },
    {
      accessorKey: 'bidStatus',
      header: t('tableHeader:Status'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 170,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['SubmittedBids'], // Custom property
    },
    {
      accessorKey: 'specialInstructions',
      header: t('tableHeader:description'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 170,
      Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
    {
      accessorKey: 'dueDate',
      header: t('tableHeader:dueDate'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 170,
      Cell: ({ cell }) => formatDateCell(cell),
      enableColumnActions: false,
      enableHiding: false,
      enableTabs: ['RecommendedJobs', 'AllJobs', 'NotInterestedJobs'],
    },
  ];

  const filteredColumns = columns.filter((column) => column.enableTabs?.includes(type));

  const mediaPopup = (
    <MediaPopup
      isOpen={isPopupOpen}
      onClose={closeMediaPopup}
      currentMediaIndex={currentMediaIndex}
      media={mediaType === MediaType.Photo ? images : videos}
      showPreviousMedia={() => showMedia('previous')}
      showNextMedia={() => showMedia('next')}
      numMedia={mediaType === MediaType.Photo ? images?.length : videos?.length}
      type={mediaType}
    />
  );

  return { columns: filteredColumns, media: mediaPopup };
};

export default useColumns;
