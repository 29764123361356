/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Box, CircularProgress } from '@mui/material';

import { GraphType, ISimpleBarGraphProps } from '../Graphs/Graphs.Model';
import { FilterName, UserType } from '../../../Constants/App';
import { getAuthDetails } from '../../../Utilities/utils';

import BarGraph from '../Graphs/BarGraph/BarGraph';
import LineGraph from '../Graphs/LineGraph/LineGraph';
import MultiBarGraph from '../Graphs/BarGraph/MultiBarGraph';
import { NoDataFound } from '../NoDataFound/NoDataFound';
import DateRangeSelection from '../DateRangeSelection/DateRangeSelection';
import CustomDropDown from '../CustomDropDown/CustomDropdown';

import theme from '../../../Themes/theme';

import { Wrapper, HeaderWrapper, Title, GraphLoader } from './GraphContainer.styles';

/**
 * Difference between GraphContainer and GraphContainerV2 is only the data filtering based on date
 * @param props
 * @returns GraphContainerV2
 */

const GraphContainerV2 = (props: ISimpleBarGraphProps): JSX.Element => {
  const {
    graphData: { data, title, graphColor, xAxisLabel, yAxisLabel },
    type,
    loading,
    onDateRangeSelect,
    id,
    handleAssociateVendorChanges,
    handleLocationChanges,
    selectedAssociateVendor,
    defaultDateRangeOption,
    defaultOption,
    selectedFacility,
    isAssociateVendorLoading,
    isFacilityLoading,
    labels,
    dateRangeOptions,
    filters,
  } = props;

  const storedAuthDetails = getAuthDetails();
  const role = storedAuthDetails['custom:Role'] as UserType;

  const renderGraph = () => {
    if ((type as GraphType) === GraphType.BarGraph) {
      return (
        <BarGraph
          graphData={data}
          graphView={props.graphData?.graphView ?? 'vertical'}
          barColor={graphColor}
          yAxisLabel={title}
        />
      );
    } else if ((type as GraphType) === GraphType.LineGraph) {
      return <LineGraph graphData={data} LineColor={graphColor} />;
    } else if ((type as GraphType) === GraphType.MultiBarGraph) {
      return <MultiBarGraph graphData={data} yAxisLabel={yAxisLabel} labels={labels} xAxisLabel={xAxisLabel} />;
    }
    return null;
  };

  return (
    <>
      {Object.keys(props.graphData)?.length || loading ? (
        <Wrapper>
          <HeaderWrapper>
            <Title>{title}</Title>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              <DateRangeSelection
                onDateRangeChange={(range) => {
                  onDateRangeSelect(range, id);
                }}
                defaultOption={defaultDateRangeOption}
                options={dateRangeOptions}
              />
              {(type as GraphType) === GraphType.MultiBarGraph && (
                <>
                  <CustomDropDown
                    title={filters.find((filter) => filter.name === FilterName.AssociateAndVendor)?.title}
                    options={
                      filters.find((filter: { name: string }) => filter.name === FilterName.AssociateAndVendor)
                        ?.options || []
                    }
                    selectedValue={selectedAssociateVendor}
                    onChange={handleAssociateVendorChanges}
                    isLoading={isAssociateVendorLoading}
                    defaultOption={defaultOption}
                  />
                  {role === UserType.Customer && (
                    <CustomDropDown
                      title={filters.find((filter) => filter.name === FilterName.Location)?.title}
                      options={
                        filters.find((filter: { name: string }) => filter.name === FilterName.Location)?.options || []
                      }
                      selectedValue={selectedFacility}
                      onChange={handleLocationChanges}
                      isLoading={isFacilityLoading}
                      defaultOption={defaultOption}
                    />
                  )}
                </>
              )}
            </Box>
          </HeaderWrapper>
          {loading ? (
            <GraphLoader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </GraphLoader>
          ) : data?.length ? (
            renderGraph()
          ) : (
            <NoDataFound />
          )}
        </Wrapper>
      ) : null}
    </>
  );
};

GraphContainerV2.defaultProps = {};

export default GraphContainerV2;
