/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../Shared/Constants/Icons';
import { CloseIconStyle } from '../CreateBid.Style';
import {
  DialogWrapper,
  PopupHeadingText,
  LogoContainer,
  ImageField,
  NameField,
  FinalQuoteValue,
  ConfirmText,
  buttonStyles,
} from './Popup.Styles';
import { Box, Divider } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import JobAwardSuccessPopup from './JobAwardSuccessPopup';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import JobAwardBidApiService from '../../../Jobs/Services/JobAwardBidService';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

interface IAwardJobProps {
  isOpen: boolean;
  onClose: () => void;
  facilityName: string;
  vendorName: string;
  finalQuote: number;
  bidDetails: any;
  facilityUrl: any;
  vendorUrl: any;
}

enum Error {
  VendorError = 'vendor:errorOccurred',
}

const AwardJobPopup: React.FC<IAwardJobProps> = ({
  isOpen,
  onClose,
  facilityName,
  vendorName,
  finalQuote,
  bidDetails,
  facilityUrl,
  vendorUrl,
}) => {
  const { organizationName = '' } = getStoredCustomerDetails() || {};

  const [isJobAwardSuccessPopupOpen, setIsJobAwardSuccessPopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const snackbarSeverity = 'success';
  const [isMarkJobAsAwarded, setIsMarkJobAsAwarded] = useState(false);

  const { t } = useTranslation(['vendor', 'dashboard']);

  const handleJobAwardSuccessPopupClick = (): void => {
    setIsJobAwardSuccessPopupOpen(true);
  };

  const markJobAsAwarded = async (): Promise<void> => {
    if (bidDetails?.customerId ?? false) {
      setIsMarkJobAsAwarded(true);
      const jobSummaryResponse = await JobCreateApiService.jobSummary(bidDetails?.customerId, bidDetails?.jobId);
      if (jobSummaryResponse?.errors?.length === 0) {
        const { jobFacilities } = jobSummaryResponse.data;
        const facility = jobFacilities?.find((item: { jobId: any }) => item.jobId === bidDetails?.jobId);
        const facilityDetailsResponse = await FacilityApiService.facility(facility.facilityId, bidDetails?.customerId);

        if (facilityDetailsResponse?.errors?.length === 0) {
          const markJobAsAwardedResponse = await JobAwardBidApiService.markJobAsAwarded(
            bidDetails?.jobId,
            bidDetails?.bidId,
            {
              facilityAddress: facilityDetailsResponse.data?.address?.addressName,
            }
          );
          if (markJobAsAwardedResponse?.data && markJobAsAwardedResponse?.errors?.length === 0) {
            setIsMarkJobAsAwarded(false);
            handleJobAwardSuccessPopupClick();
          } else {
            setIsMarkJobAsAwarded(false);
            setSnackbarMessage(t(Error.VendorError));
            setSnackbarOpen(true);
          }
        } else {
          setIsMarkJobAsAwarded(false);
          setSnackbarMessage(t(Error.VendorError));
          setSnackbarOpen(true);
        }
      } else {
        setIsMarkJobAsAwarded(false);
        setSnackbarMessage(t(Error.VendorError));
        setSnackbarOpen(true);
      }
    }
  };

  const handleClose = (): void => {
    onClose();
    setIsJobAwardSuccessPopupOpen(false);
  };

  return (
    <>
      <DialogWrapper open={isOpen} onClose={handleClose}>
        <CloseIconStyle onClick={handleClose}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </CloseIconStyle>
        <PopupHeadingText>{t('dashboard:awardBid')}</PopupHeadingText>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}>
          <LogoContainer>
            {facilityUrl ? (
              <img
                src={facilityUrl}
                style={{
                  height: '3.5rem',
                  width: '3.5rem',
                  background: theme.palette.secondary.customColor7,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.h5?.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                alt={t('altTexts:companyLogo')}
              />
            ) : (
              <ImageField>{organizationName[0]}</ImageField>
            )}
          </LogoContainer>
          <Divider sx={{ ...buttonStyles.divider }} />

          <LogoContainer>
            {vendorUrl ? (
              <img
                src={vendorUrl}
                style={{
                  height: '3.5rem',
                  width: '3.5rem',
                  background: theme.palette.secondary.customColor7,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.h5?.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                alt={t('altTexts:companyLogo')}
              />
            ) : (
              <ImageField>{vendorName[0]}</ImageField>
            )}
          </LogoContainer>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '2.625rem',
            gap: '1.5rem',
          }}>
          <NameField sx={{ width: '50%' }}>{organizationName}</NameField>
          <NameField sx={{ width: '50%' }}>{vendorName}</NameField>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '2.625rem' }}>
          <PopupHeadingText sx={{ fontSize: theme.typography.h5?.fontSize, marginBottom: 0 }}>
            {t('vendor:finalQuote')}
          </PopupHeadingText>
          <FinalQuoteValue>{`$ ${finalQuote.toFixed(2)}`}</FinalQuoteValue>
        </Box>
        <ConfirmText sx={{ display: 'flex', marginBottom: '1.5rem', gap: '0.125rem' }}>
          {t('dashboard:pleaseClickOn')}{' '}
          <span style={{ fontWeight: theme.typography.fontWeightBold }}>{t('dashboard:confirm')}</span>{' '}
          {t('dashboard:proceed')}
        </ConfirmText>
        <Button
          type={ButtonType.Primary}
          width="100%"
          label={t('vendor:confirm')}
          isLoading={isMarkJobAsAwarded}
          onClick={() => {
            markJobAsAwarded();
          }}
        />
        {isJobAwardSuccessPopupOpen && (
          <JobAwardSuccessPopup
            isOpen={true}
            onClose={() => handleClose()}
            facilityName={facilityName}
            vendorName={vendorName}
            finalQuote={finalQuote}
            facilityUrl={facilityUrl}
            vendorUrl={vendorUrl}
          />
        )}
      </DialogWrapper>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AwardJobPopup;
