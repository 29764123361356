/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Tabs } from '@mui/material';
import moment from 'moment';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Facility,
  JobAttachment,
  JobBidVendor,
  JobDueDate,
  JobFacilityWithOnlyId,
  JobFrequency,
  JobSummaryV2,
  JobType,
  ServiceCategoryV3,
  WorkOrder,
} from '../../../../API';

import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import {
  getStoredCustomerDetails,
  isMock,
  ParseFloat,
  checkImageValid,
  getAuthDetails,
  WorkOrderStatus,
  getAuthData,
  formatDate,
} from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import JobCreateApiService from '../../Services/JobCreateService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { Loader } from '../JobView/JobsView.styles';
import { ErrorMessage, ErrorText } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import { NoDataFound } from '../../../../Shared/Components/Common/NoDataFound/NoDataFound';

import { UserType } from '../../../../Shared/Services/AnalyticsService';
import { Storage } from 'aws-amplify';
import theme from '../../../../Shared/Themes/theme';

import { DraftStatus, DraftStep, states } from '../../../../Shared/Constants/App';
import {
  IJobData,
  IPerformanceMetrics,
  IServices,
  IVendorDetails,
  ShiftTypes,
} from '../../Models/JobDetailsOverview.Model';
import { Icons } from '../../../../Shared/Constants/Icons';

import {
  PerformanceMetricsWrapper,
  ProgressBar,
  ProgressBarWrapper,
  Card,
  CardWrapper,
  TaskStatusWrapper,
  StatusWrapper,
  ProgressBarContainer,
  Timings,
  AssociateTimingWrapper,
  AssociateDetails,
  TabTitle,
  TabsWrapper,
  TabSection,
  FacilityInfoWrapper,
  FacilityDetails,
  FaiclityJobName,
  FacilityDuration,
  FacilityShiftDetails,
  FacilityShiftList,
  ShiftTime,
  ShiftType,
  ServicesList,
  VendorDetails,
  VendorInfoWrapper,
  VendorInfo,
  IconWrapper,
  PersonIcon,
  NameWrapper,
  PersonName,
  Name,
  DropdownWrapper,
  PerformanceMetrics,
  DateWrapper,
  FacilityDurationWrapper,
  TitleText,
  TitleStatus,
  IssuesRaised,
  OneTimeJobs,
  DateContainer,
  ChangeDate,
  PieChartScore,
  ClockWrapper,
  AssociateDurationDetails,
  ProgressLoader,
  DaysRange,
} from './JobDetailsOverview.styles';
import ActionItems from '../ActionItems/ActionItems';
import AssociateCard from '../AssociateOnTheJob/AssociateCard';
import SimpleDatePicker from '../../../../Shared/Components/Common/SimpleDatePicker/SimpleDatePicker';
import { CustomerContainer, NoDataWrapper } from '../../../../Shared/Components/Layout/styles';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import Dropdown from '../../../../Shared/Components/Common/Dropdown/Dropdown';
import JobDetailsTasks from '../WorkTasks/WorkTasks';
import { useForm, useWatch } from 'react-hook-form';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

export const renderImg = (type: ShiftTypes) => {
  if (ShiftTypes.Day === type) {
    return <img src={Icons.DayIcon} alt={t('altTexts:day')} />;
  } else if (ShiftTypes.DayAndNight === type) {
    return <img src={Icons.DayAndNightIcon} alt={t('altTexts:dayAndNight')} />;
  } else if (ShiftTypes.Night === type) {
    return <img src={Icons.NightIcon} alt={t('altTexts:night')} />;
  }
  return null;
};

/**
 *
 * @returns JobDetailsOverview
 */

export interface JobWithDueDates {
  jobId: string;
  actualId?: string | null;
  jobName: string;
  name: string;
  startDate?: string | null;
  actualStartDate?: string | null;
  actualEndDate?: string | null;
  stopDate?: string | null;
  dataStatus?: string | null;
  jobType?: string | null;
  specialInstructions?: string | null;
  draftStep?: DraftStep | null;
  draftStatus?: DraftStatus | null;
  createdOn?: string | null;
  createdBy?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  createdByName?: string | null;
  modifiedByName?: string | null;
  bidCount?: number | null;
  jobCustodian?: string | null;
  intId?: string | null;
  allowVendorToReschedule?: boolean | null;
  jobFacilities?: Array<JobFacilityWithOnlyId | null> | null;
  jobDueDates?: Array<JobDueDate | null> | null;
  jobBids?: Array<JobBidVendor | null> | null;
  jobFrequencies?: Array<JobFrequency | null> | null;
  jobAttachments?: Array<JobAttachment | null> | null;
  vendorId?: string | null | undefined;
}

const JobDetailsOverview = () => {
  const { t } = useTranslation(['dashboard', 'altTexts']);
  const [selectedFacility, setSelectedFacility] = useState<IJobData>();
  const location = useLocation();
  const navigate = useNavigate();
  const [tabSelected, setTabSelected] = useState(() => {
    if (location?.pathname.includes('worktasks')) return 1;
    if (location?.pathname.includes('associateOnTheJob')) return 2;
    if (location?.pathname.includes('actionItems')) return 3;
    return 0;
  });
  const newDate = location?.state?.selectedDate;
  const [facilities, setFacilities] = useState<any>([]);
  const [allJobs, setAllJobs] = useState<any>([]);
  const [isServicesLoading, setIsServicesLoading] = useState<boolean>(true);
  const [facilityId, setFacilityId] = useState('');
  const [selectedJob, setSelectedJob] = useState<string>('');
  const [selectedJobDetails, setSelectedJobDetails] = useState<JobSummaryV2>();
  const [vendorDetails, setVendorDetails] = useState<IVendorDetails>();
  const [frequencies, setFrequencies] = useState([]);
  const [serviceData, setServiceData] = useState<IServices[]>([]);
  const [facilityLoading, setFacilityLoading] = useState<boolean>(true);
  const [isFacilityDropDownLoading, setIsFacilityDropDownLoading] = useState<boolean>(true);
  const [vendorLoading, setVendorLoading] = useState<boolean>(true);
  const [isVendorQueryError, setIsVendorQueryError] = useState<boolean>(false);
  const [isJobSummaryError, setIsJobSummaryError] = useState<boolean>(false);
  const isAnalyticsAPIError: boolean = false;
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  const [jobsDataLoading, setJobsDataLoading] = useState<boolean>(true);
  const performanceMetrics: IPerformanceMetrics[] = [];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [status, setStatus] = useState();
  const performanceLoader = false;
  const [startStopDate, setStartStopDate] = useState<any>({});
  const [vendorTypes, setVendorTypes] = useState<any>({});
  const validImgRef = useRef(false);

  const [jobType, setJobType] = useState('');

  const { startDate, stopDate } = startStopDate;
  const isDateLoading = !startDate || !stopDate;

  const storedAuthDetails = getAuthDetails();
  const NUMBER_OF_ITEMS_PER_PAGE = 10;
  const { role, isDistrictManager } = getAuthData() || {};
  const CUSTOM_ROLE_KEY = 'custom:Role';

  const [selectedFacilityData, setSelectedFacilityData] = useState();
  const [selectedJobData, setSelectedJobData] = useState<WorkOrder>();
  const [jobStartDate, setJobStartDate] = useState<string>('');
  const [jobEndDate, setJobEndDate] = useState<string>('');

  const { control, setValue } = useForm({
    defaultValues: {
      specificDate: newDate ? newDate : new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  useEffect(() => {
    if (location?.state?.selectedDate) {
      setValue('specificDate', location?.state?.selectedDate);
      return;
    }
    if (!selectedJobData) return;
    const {
      actualStartDate = '',
      actualEndDate = '',
      jobStartDate = '',
      jobEndDate = '',
    } = selectedJobData || { actualStartDate: '', actualEndDate: '', jobStartDate: '', jobEndDate: '' };
    if ((actualStartDate && actualEndDate) || (jobStartDate && jobEndDate)) {
      let startDate: string = actualStartDate || jobStartDate || '';
      startDate = startDate.endsWith('Z') ? startDate.slice(0, startDate?.length - 1) : startDate;
      const endDate: string = actualEndDate || jobEndDate || '';
      const isCurrentJobInTheRangeOfJobDurationForRecurring = moment().isBetween(startDate, endDate);
      if (selectedJobData?.jobType === JobType.Recurring && isCurrentJobInTheRangeOfJobDurationForRecurring) {
        let currentDeviceDate = new Date()?.toJSON();
        currentDeviceDate = currentDeviceDate?.slice(0, currentDeviceDate?.length - 1);
        setValue('specificDate', currentDeviceDate);
      } else {
        setValue('specificDate', startDate);
      }
    }
  }, [selectedJobData]);

  const handleChangeTabs = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    navigate(
      `/job-detail/${newValue === 0 ? 'overview' : newValue === 1 ? 'worktasks' : newValue === 2 ? 'associateOnTheJob' : 'actionItems'}`,
      {
        state: { ...location.state },
      }
    );
  }, []);

  const fetchServices = async (jobId: string, facilityCustomerId: string) => {
    setIsServicesLoading(true);
    let { customerId = '' } = getStoredCustomerDetails() || {};

    if (storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Vendor) {
      customerId = facilityCustomerId;
    }

    try {
      const serviceCategories = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      if (serviceCategories.data) {
        const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId, isMock);
        if (jobSummaryResponse.data && jobSummaryResponse.errors.length === 0) {
          const { jobFrequencies, jobType, startDate, stopDate, jobVendors } = jobSummaryResponse.data;
          setVendorTypes(jobVendors);
          setJobType(jobType);

          const frequencies = jobFrequencies.map((frequency: any) => {
            const serviceList = frequency.mdServiceId.map((item: string) => {
              const category = serviceCategories?.data.find((categoryItem: ServiceCategoryV3) => {
                return categoryItem.serviceCategoryId === item;
              });
              return {
                categoryId: category?.serviceCategoryId ?? '',
                categoryName: category?.serviceCategoryName ?? '',
                icon: category?.categoryImageUrl ?? '',
              };
            });

            return {
              shiftType: frequency.mdShiftType,
              shiftTiming: frequency.mdScheduleType,
              services: serviceList,
            };
          });

          const servicesList: any = [];
          Array.isArray(frequencies) &&
            frequencies.forEach((freq: any) => {
              const frequency = freq.services.map((service: any) => {
                return {
                  serviceName: service.categoryName,
                  mdServiceId: service.categoryId,
                  iconUrl: service.icon,
                  shiftType: freq.shiftType,
                  shiftTiming: freq.shiftTiming,
                };
              });
              frequency.forEach((dataItem: any) => {
                servicesList.push({
                  ...dataItem,
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  id: servicesList.length + 1,
                });
              });
            });

          setServiceData(servicesList);
          setFrequencies(frequencies);
          setStartStopDate({ startDate, stopDate });
          setIsJobSummaryError(false);
        } else {
          setIsJobSummaryError(true);
          console.error('jobSummaryV2 queries: ', jobSummaryResponse.errors);
        }
      } else {
        setIsJobSummaryError(true);
        console.error('serviceCategoryV2 queries: ', serviceCategories.errors);
      }
    } catch (e) {
      console.error('Error', e);
    } finally {
      setIsServicesLoading(false);
    }
  };

  const getVendorDetailsComp = (vendorDetails: {
    vendorPersonIcon: string;
    vendorPersonName: string;
    vendorName: string;
    vendorLocation: string;
    VendorPersonMailId: string;
    vendorContactNumber: string;
  }) => {
    const { vendorPersonIcon, vendorPersonName, vendorName, VendorPersonMailId, vendorContactNumber } = vendorDetails;
    checkImageValid(
      vendorPersonIcon,
      function () {
        validImgRef.current = true;
      },
      function () {
        validImgRef.current = false;
      }
    );

    const vendorImgUrl = validImgRef.current ? vendorPersonIcon : Icons.VendorDetailsLogo;

    return (
      <VendorInfoWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <PersonIcon>
            <img src={vendorImgUrl} alt={t('altTexts:vendorImage')} />
          </PersonIcon>
          <Box sx={{ display: 'flex', paddingLeft: '1rem', width: '100%', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <PersonName>{vendorPersonName}</PersonName>
              <Name>{vendorName}</Name>
            </Box>
            <Box sx={{ flex: 1 }}>
              <VendorDetails>
                {VendorPersonMailId ? (
                  <VendorInfo>
                    <img src={Icons.EmailIcon} alt={t('altTexts:vendorEmail')} />
                    <p>{VendorPersonMailId}</p>
                  </VendorInfo>
                ) : null}
                {vendorContactNumber ? (
                  <VendorInfo>
                    <img src={Icons.PhoneNoIcon} alt={t('altTexts:vendorContact')} />
                    <p>{vendorContactNumber}</p>
                  </VendorInfo>
                ) : null}
              </VendorDetails>
            </Box>
          </Box>
        </Box>
      </VendorInfoWrapper>
    );
  };

  const fetchFacilityData = async () => {
    try {
      setIsFacilityDropDownLoading(true);
      let workOrderId = location.state?.facilityItem?.workOrderId;
      if (!workOrderId) {
        const allJobResponse = await WorkOrdersApiService.getAllWorkOrders({
          jobId: location?.state?.jobId,
        });
        workOrderId = allJobResponse?.data[0]?.workOrderId;
      }
      const workOrderResponseByWorkOrderId = await WorkOrdersApiService.getAllWorkOrders({
        workOrderId: workOrderId,
      });
      const allFacilities = await FacilityApiService.getAllFacilities(
        workOrderResponseByWorkOrderId?.data[0]?.customerId
      );
      const modifiedFacilities = allFacilities.data.map((facilityItem: any) => {
        return {
          id: facilityItem?.facilityId,
          label: facilityItem?.buildingName,
          value: facilityItem?.facilityId,
          ...facilityItem,
        };
      });
      const facility = modifiedFacilities.find((data: any) => data.id === location.state.facilityItem.facilityId);
      setFacilityId(location.state.facilityItem.facilityId);
      setSelectedFacility(facility);
      setFacilities(modifiedFacilities);
    } catch (error) {
      console.error('Error fetching facility data:', error);
    } finally {
      setIsFacilityDropDownLoading(false);
    }
  };

  const fetchAllFacilities = async () => {
    setIsFacilityDropDownLoading(true);
    setFacilityLoading(true);
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};
      if (storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Customer && customerId) {
        const allFacilitiesResponse = await FacilityApiService.getAllFacilities(customerId);
        if (allFacilitiesResponse.errors.length === 0 && allFacilitiesResponse.data.length) {
          const modifiedFacilities = allFacilitiesResponse.data.map((dataItem: Facility) => {
            return { id: dataItem.facilityId, label: dataItem.buildingName, value: dataItem.facilityId, ...dataItem };
          });

          setFacilities(modifiedFacilities);

          handleSelectedFacilityAndFacilityId(allFacilitiesResponse, modifiedFacilities);
        } else {
          console.error('facilities query : ', allFacilitiesResponse, customerId);
        }
      } else if (storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Vendor && customerId) {
        await fetchFacilityData();
      }
      setFacilityLoading(false);
    } catch (e) {
      console.error('Error', e);
      setFacilityLoading(false);
    } finally {
      setFacilityLoading(false);
      setIsFacilityDropDownLoading(false);
    }
  };

  const handleSelectedFacilityAndFacilityId = (allFacilitiesResponse: any, modifiedFacilities: any) => {
    const facilityId = location.state?.facilityItem?.facilityId || location.state?.facilityItem?.facility;
    if (facilityId) {
      if (allFacilitiesResponse.data.length) {
        const facility = allFacilitiesResponse.data.find((data: any) => data.facilityId === facilityId);
        setFacilityId(facilityId);
        setSelectedFacility(facility);
      }
    } else {
      const facility = modifiedFacilities[0];
      if (facility) {
        setFacilityId(facility.facilityId);
        setSelectedFacility(facility);
      }
    }
  };

  const getJobsResponse = async (customerId: string, facilityId: string) => {
    if ((role as UserType) === UserType.Vendor) {
      const { customerId }: { customerId: string } = getStoredCustomerDetails() || '';

      let allOngoingInProgressJobsResponse = await WorkOrdersApiService.getAllWorkOrders({
        vendorId: customerId,
        facilityId,
      });
      if (
        allOngoingInProgressJobsResponse?.metadata &&
        allOngoingInProgressJobsResponse?.metadata?.totalCount > NUMBER_OF_ITEMS_PER_PAGE
      ) {
        allOngoingInProgressJobsResponse = await WorkOrdersApiService.getAllWorkOrders(
          {
            vendorId: customerId,
            facilityId,
            limit: allOngoingInProgressJobsResponse?.metadata?.totalCount,
          },
          isMock
        );
      }

      return allOngoingInProgressJobsResponse;
    } else {
      let allOngoingInProgressJobsResponse = await WorkOrdersApiService.getAllWorkOrders({
        customerId: customerId,
        facilityId,
      });
      if (
        allOngoingInProgressJobsResponse?.metadata &&
        allOngoingInProgressJobsResponse?.metadata?.totalCount > NUMBER_OF_ITEMS_PER_PAGE
      ) {
        allOngoingInProgressJobsResponse = await WorkOrdersApiService.getAllWorkOrders(
          {
            customerId: customerId,
            facilityId,
            limit: allOngoingInProgressJobsResponse?.metadata?.totalCount,
          },
          isMock
        );
      }

      return allOngoingInProgressJobsResponse;
    }
  };

  const getCustomerIdBasedOnRole = (facilityCustomerId: string) => {
    let { customerId = '' } = getStoredCustomerDetails() || {};
    if (storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Vendor) {
      customerId = facilityCustomerId;
    }
    return customerId;
  };

  const fetchJobs = useCallback(async (facilityId: string, facilityCustomerId: string) => {
    try {
      const customerId = getCustomerIdBasedOnRole(facilityCustomerId);
      const jobsResponse = await getJobsResponse(customerId, facilityId);
      const facilityDetailsResponse = await FacilityApiService.facility(facilityId, customerId, isMock);

      let facilityIcon: any = '';
      const logoUrl = facilityDetailsResponse?.data?.address?.logoUrl;
      if (facilityDetailsResponse?.data && logoUrl) {
        facilityIcon = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      }
      if (jobsResponse?.data && jobsResponse?.errors.length === 0) {
        return jobsResponse?.data.map((jobItem: JobWithDueDates) => {
          return {
            startDate: jobItem.startDate ?? jobItem.actualStartDate,
            stopDate: jobItem.stopDate ?? jobItem.actualEndDate,
            value: jobItem.jobId,
            facilityId: facilityId ?? '',
            jobId: jobItem?.jobId ?? '',
            id: jobItem.jobId,
            label: jobItem.jobName ?? jobItem.name,
            facilityName: facilityDetailsResponse?.data?.buildingName || '',
            icon: facilityIcon,
            location: facilityDetailsResponse?.data?.address?.addressName || '',
            vendorId: jobItem.jobBids?.[0]?.vendorId ?? jobItem.vendorId,
          };
        });
      }
      if (jobsResponse?.errors.length) {
        console.error('Error', jobsResponse?.errors);
        return [];
      }
      return [];
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const fetchOngoingJobs = useCallback(async (facilityId: string, facilityCustomerId: string) => {
    let { customerId = '' } = getStoredCustomerDetails() || {};

    if (storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Vendor) {
      customerId = facilityCustomerId;
    }

    try {
      const allJobResponse = await WorkOrdersApiService.getAllWorkOrders(
        {
          customerId,
          facilityId,
        },
        isMock
      );

      if (allJobResponse?.data?.length && allJobResponse?.errors?.length === 0) {
        const jobsArray: WorkOrder[] = [];
        allJobResponse.data.forEach((jobdata: WorkOrder) => {
          if (!jobdata.parentWorkOrderId) {
            jobsArray.push(jobdata);
          }
        });
        return getAllOnGoingJobs(jobsArray);
      }
      if (allJobResponse?.errors?.length && allJobResponse?.errors[0]?.message) {
        console.error('Error', allJobResponse?.errors);
        return [];
      }
      return [];
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const getAllOnGoingJobs = async (jobsArray: WorkOrder[]) => {
    return await Promise.all(
      jobsArray.map(async (job: WorkOrder) => {
        const { name, facilities, customerId } = job;
        let facilityIcon;
        let facilityId = '';
        if (facilities) {
          facilityId = facilities[0]?.facilityId ?? '';
        }
        const facilityAddressResponse = await FacilityApiService.facility(facilityId, customerId ?? '', isMock);
        if (facilityAddressResponse?.data) {
          const logoUrl = facilityAddressResponse?.data?.address?.logoUrl;
          if (logoUrl) {
            facilityIcon = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
          }
        }
        return {
          label: name,
          startDate: job.jobStartDate,
          stopDate: job.jobEndDate,
          facilityName: facilityAddressResponse?.data?.buildingName || '',
          value: job?.jobId ?? '',
          facilityId: facilityId ?? '',
          jobId: job?.jobId ?? '',
          id: job?.workOrderId,
          icon: facilityIcon,
          location: facilityAddressResponse?.data?.address?.addressName || '',
          vendorId: job?.vendorId,
        };
      })
    );
  };

  const fetchAllJobs = useCallback(
    async (facilityId: string, customerId: string) => {
      const [allJobs] = await Promise.all([fetchJobs(facilityId, customerId)]);

      if (allJobs?.length) {
        return [...allJobs];
      }
      return [];
    },
    [fetchJobs, fetchOngoingJobs]
  );

  const fetchVendorDetails = useCallback(async (vendorId: string) => {
    if (!!vendorId ?? false) {
      setVendorLoading(true);
      const vendorResponse = await FacilityApiService.vendorDetails(vendorId, isMock);
      const vendorAddress = await FacilityApiService.getVendorAddresses(vendorId, isMock);
      const resultLogo = await Storage.get(`vendor/${vendorId}/company-logos/${vendorId}`, { level: 'public' });
      if (vendorResponse.errors?.length) {
        setIsVendorQueryError(true);
        console.error('vendor query: ', vendorResponse);
      } else if (vendorAddress.errors?.length) {
        setIsVendorQueryError(true);
        console.error('vendorAddresses query: ', vendorAddress);
      } else {
        const { vendorCompanyName, phone, email } = vendorResponse.data;
        const { addressLine1, addressLine2, addressLine3 } = getVendorAddress(vendorAddress);
        setVendorDetails({
          vendorPersonName: vendorCompanyName ?? '',
          vendorPersonIcon: resultLogo ?? '',
          vendorName: `${addressLine1 ?? ''} ${addressLine2 ?? ''} ${addressLine3 ?? ''}`,
          vendorLocation: '',
          VendorPersonMailId: email ?? '',
          vendorContactNumber: phone ?? '',
        });
        setIsVendorQueryError(false);
      }
    }
    setVendorLoading(false);
  }, []);

  const getVendorAddress = (vendorAddress: any) => {
    const { addressLine1, addressLine2, addressLine3 } = vendorAddress.data?.length
      ? vendorAddress.data[0]
      : { addressLine3: '', addressLine1: '', addressLine2: '' };
    return { addressLine1, addressLine2, addressLine3 };
  };

  const getJobIdByWorkOrderId = async (workOrderId: string) => {
    const workOrderResponseByWorkOrderId = await WorkOrdersApiService.getAllWorkOrders({ workOrderId });
    if (workOrderResponseByWorkOrderId.data && !isEmpty(workOrderResponseByWorkOrderId.errors)) {
      return workOrderResponseByWorkOrderId.data[0].jobId;
    } else {
      return '';
    }
  };

  const fetchJobsForSelectedFacility = useCallback(async (facilityId: string, customerId: string) => {
    setJobsLoading(true);
    const jobs: any = await fetchAllJobs(facilityId, customerId);
    let selectedJobId: string;
    if (location.state && location.state.jobId) {
      selectedJobId = location.state.jobId;
    } else if (location.state?.facilityItem?.workOrderId) {
      selectedJobId = await getJobIdByWorkOrderId(location.state?.facilityItem?.workOrderId);
    } else {
      selectedJobId = '';
    }

    if (jobs && jobs.length > 0) {
      setAllJobs(jobs);
      setSelectedJob(selectedJobId ? selectedJobId : jobs[0].value);
    } else {
      setAllJobs([]);
      setSelectedJob('');
    }
    setJobsLoading(false);
  }, []);

  const renderPerformanceMatrix = () => {
    return (
      <>
        <PerformanceMetrics>{t('dashboard:performanceMetrics')}</PerformanceMetrics>

        {performanceLoader ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : performanceMetrics.length ? (
          <>
            {performanceMetrics.map((dataItem, index) => {
              const progress = dataItem?.totalTasks !== 0 ? (dataItem.completed / dataItem.totalTasks) * 100 : 0;
              return (
                <PerformanceMetricsWrapper key={index}>
                  {renderDateContainer(dataItem, index)}
                  {/* <DateContainer>
                    <div>
                      <img src={Icons.Calendar} alt={t('altTexts:calendar')} />
                      <DateWrapper>
                        {index === 0 ? (
                          dataItem.title
                        ) : (
                          <>
                            {dataItem.title}
                            <DaysRange>{dataItem.days}</DaysRange>
                          </>
                        )}
                      </DateWrapper>
                    </div>
                    {index === 0 ? (
                      <SimpleDatePicker
                        isResponsive={false}
                        defaultValue={selectedDate}
                        onChange={(date) => setSelectedDate(moment(date).toDate())}
                        CustomComponent={
                          <ChangeDate>
                            {t('dashboard:changeDate')} <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
                          </ChangeDate>
                        }
                      />
                    ) : null}
                  </DateContainer> */}
                  <AssociateDetails>
                    <TaskStatusWrapper>
                      <StatusWrapper>
                        <h5>
                          {t('dashboard:completed')}: <strong>{dataItem.completed}</strong>
                        </h5>
                        <h5>
                          {t('dashboard:incomplete')}: <strong>{dataItem.totalTasks - dataItem.completed}</strong>
                        </h5>
                      </StatusWrapper>
                      <ProgressBarWrapper>
                        <h5>0 tasks</h5>
                        <ProgressBarContainer>
                          <ProgressBar progressPercent={progress}></ProgressBar>
                        </ProgressBarContainer>
                        <h5>{dataItem.totalTasks} tasks</h5>
                      </ProgressBarWrapper>
                    </TaskStatusWrapper>
                    {dataItem.punchInTime && dataItem.punchOutTime ? (
                      <AssociateTimingWrapper>
                        <div>
                          <ClockWrapper>
                            <img src={Icons.ClockIcon} alt={t('altTexts:time')} />
                          </ClockWrapper>
                          <AssociateDurationDetails>
                            <Timings>{`${dataItem.punchInTime} - ${dataItem.punchOutTime}`}</Timings>
                            <h5>{t('dashboard:associatesOnsite')}</h5>
                          </AssociateDurationDetails>
                        </div>
                      </AssociateTimingWrapper>
                    ) : null}
                  </AssociateDetails>
                  <CardWrapper>
                    <Card>
                      <div>
                        <PieChartScore score={dataItem.inspectionScore}></PieChartScore>
                        <TitleStatus>{`${ParseFloat(dataItem?.inspectionScore?.toString(), 2)}%`}</TitleStatus>
                      </div>
                      <TitleText>{t('dashboard:inspectionScore')}</TitleText>
                    </Card>
                    {/* <Card>
                          <div>
                            <img src={Icons.StarRatingFilledIcon} alt={t('altTexts:startRating')} />
                            <TitleStatus>{dataItem.satisfactionScore}</TitleStatus>
                          </div>
                          <TitleText>Satisfaction score</TitleText>
                        </Card> */}
                    <IssuesRaised to="/jobs-issues-raised">
                      <div>
                        <img src={Icons.TicketIcon} alt={t('altTexts:ticket')} />
                        <TitleStatus>{dataItem.issuesRaised}</TitleStatus>
                      </div>
                      <div>
                        <TitleText>{t('dasboard:issuesRaised')}</TitleText>
                        <img src={Icons.ChevronRightIcon} alt={t('altTexts:rightArrow')} />
                      </div>
                    </IssuesRaised>
                    <OneTimeJobs state={{ isOneTimeJob: true }} to={{ pathname: '/jobs-view/onetime' }}>
                      <div>
                        <img src={Icons.JobIcon} alt={t('altTexts:jobs')} />
                        <TitleStatus>{dataItem.oneTimeJobs}</TitleStatus>
                      </div>
                      <div>
                        <TitleText>{t('dasboard:oneTimeJobs')}</TitleText>
                        <img src={Icons.ChevronRightIcon} alt={t('altTexts:rightArrow')} />
                      </div>
                    </OneTimeJobs>
                  </CardWrapper>
                </PerformanceMetricsWrapper>
              );
            })}
          </>
        ) : isAnalyticsAPIError ? (
          <ErrorMessage />
        ) : (
          <NoDataFound />
        )}
      </>
    );
  };

  const renderDateContainer = (dataItem: any, index: number) => {
    return (
      <DateContainer>
        <div>
          <img src={Icons.Calendar} alt={t('altTexts:calendar')} />
          <DateWrapper>
            {index === 0 ? (
              dataItem.title
            ) : (
              <>
                {dataItem.title}
                <DaysRange>{dataItem.days}</DaysRange>
              </>
            )}
          </DateWrapper>
        </div>
        {index === 0 ? (
          <SimpleDatePicker
            isResponsive={false}
            defaultValue={selectedDate}
            onChange={(date) => setSelectedDate(moment(date).toDate())}
            CustomComponent={
              <ChangeDate>
                {t('dashboard:changeDate')} <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
              </ChangeDate>
            }
          />
        ) : null}
      </DateContainer>
    );
  };

  const getFacilityAddress = () => {
    return `${addressLine1 ?? ''} ${addressLine2 ?? ''}, ${
      addressLine3 !== 'undefined' && addressLine3 !== null ? addressLine3 : ''
    } ${city ?? ''}, ${states[stateCode] ?? ''} ${county ?? ''}`;
  };

  const renderOverviewTab = () => {
    return (
      <>
        <FacilityInfoWrapper>
          <FacilityDetails>
            <IconWrapper>
              <PersonIcon>
                <img src={FacilityIcon} alt={t('altTexts:facility')} width={56} height={56} />
              </PersonIcon>
              <NameWrapper>
                <PersonName>{buildingName}</PersonName>
                <Name>{getFacilityAddress()}</Name>
              </NameWrapper>
            </IconWrapper>
            <FacilityDurationWrapper>
              <FaiclityJobName>{selectedJobDetails?.jobName}</FaiclityJobName>
              <FacilityDuration>
                {isDateLoading ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.primary.dark }} />
                ) : (
                  `${t('dashboard:duration')} ${formatDate(startDate, 'MMM D, YYYY')} to ${formatDate(stopDate, 'MMM D, YYYY')}`
                )}
              </FacilityDuration>
              {status && <FacilityDuration>Status : {WorkOrderStatus[status] ?? '-'}</FacilityDuration>}
            </FacilityDurationWrapper>
          </FacilityDetails>
          {isServicesLoading ? (
            <ProgressLoader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </ProgressLoader>
          ) : (
            <>{renderFacilityShiftDetails()}</>
          )}
        </FacilityInfoWrapper>
        {(isDistrictManager && location?.state?.executionType !== 'self') ||
        (role as UserType) === UserType.Customer ? (
          <>
            {!vendorLoading && vendorDetails ? (
              getVendorDetailsComp(vendorDetails)
            ) : (
              <NoDataWrapper>
                {vendorLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                ) : isVendorQueryError ? (
                  <ErrorText />
                ) : (
                  `${t('dashboard:noVendorFound')}`
                )}
              </NoDataWrapper>
            )}
          </>
        ) : null}
        <>
          {/* eslint-disable-next-line sonarjs/no-redundant-boolean */}
          {false && renderPerformanceMatrix()}
        </>
      </>
    );
  };

  const renderFacilityShiftDetails = () => {
    return (
      <FacilityShiftDetails>
        {Array.isArray(frequencies) && frequencies.length ? (
          frequencies.map((data: any, index) => {
            return (
              <FacilityShiftList key={index}>
                {jobType == 'Recurring' && (
                  <>
                    <ShiftTime>{data.shiftTiming}</ShiftTime>
                    <ShiftType>
                      {renderImg(data.shiftType)}
                      {data.shiftType}
                    </ShiftType>
                  </>
                )}
                <ServicesList>{data.services.map((data: any) => data.categoryName).join(', ')}</ServicesList>
              </FacilityShiftList>
            );
          })
        ) : isJobSummaryError ? (
          <ErrorText />
        ) : (
          <Loader
            sx={{
              marginTop: '1.5rem',
              fontSize: theme.typography.h3?.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            }}>
            {t('dashboard:noServicesFound')}
          </Loader>
        )}
      </FacilityShiftDetails>
    );
  };

  const {
    FacilityIcon = Icons.BuildingImg,
    buildingName = '',
    address: { addressLine1 = '', addressLine2 = '', addressLine3 = '', city = '', county = '', stateCode = '' } = {},
  } = selectedFacility || {};

  useEffect(() => {
    const job = allJobs.find((data: any) => data.jobId === selectedJob);
    if (job?.vendorId) {
      fetchVendorDetails(job?.vendorId);
    } else {
      setVendorLoading(false);
      setVendorDetails(undefined);
    }
    if (selectedJob) {
      fetchServices(selectedJob, selectedFacility?.customerId || '');
    }
  }, [selectedJob]);

  useEffect(() => {
    fetchAllFacilities();
  }, []);

  useEffect(() => {
    if (facilityId) {
      const selectedFacilityVal = facilities.find((facilityItem: any) => facilityItem.id === facilityId);
      // setting selectedFacilityVal here
      setSelectedFacility(selectedFacilityVal);
      fetchJobsForSelectedFacility(facilityId, selectedFacilityVal?.customerId);
    }
    if (selectedJob) {
      const selectedJobVal = allJobs.find((jobItem: any) => jobItem.jobId === selectedJob);
      setSelectedJobDetails(selectedJobVal);
    }
  }, [facilityId]);

  useEffect(() => {
    if (!facilityId && location.state && location.state.facilityId) {
      const selectedFacilityVal = facilities.find((facilityItem: any) => facilityItem.id === location.state.facilityId);
      // setting selectedFacilityVal here
      setSelectedFacility(selectedFacilityVal);
      fetchJobsForSelectedFacility(
        location.state.facilityId,
        selectedFacilityVal?.customerId || location.state.customerId
      );
    }
    if (selectedJob) {
      const selectedJobVal = allJobs.find((jobItem: any) => jobItem.jobId === selectedJob);
      setSelectedJobDetails(selectedJobVal);
    }
  }, [facilityId]);

  useEffect(() => {
    if (window.location.pathname.includes('worktasks')) {
      setTabSelected(1);
    } else if (window.location.pathname.includes('overview')) {
      setTabSelected(0);
    } else if (window.location.pathname.includes('associateOnTheJob')) {
      setTabSelected(2);
    } else if (window.location.pathname.includes('actionItems')) {
      setTabSelected(3);
    }
  }, [window.location.pathname]);

  const fetchSelectedFacilityData = async () => {
    try {
      const workOrderResponseByWorkOrderId = await WorkOrdersApiService.getAllWorkOrders({
        workOrderId: location.state?.facilityItem?.workOrderId,
      });
      if (facilityId && workOrderResponseByWorkOrderId?.data[0]?.customerId) {
        const facilityResponse = await FacilityApiService.facility(
          facilityId,
          workOrderResponseByWorkOrderId?.data[0]?.customerId
        );
        setSelectedFacilityData(facilityResponse?.data);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const fetchSelectedJobData = async () => {
    setJobsDataLoading(true);
    try {
      const workOrderResponseByJobId = await WorkOrdersApiService.getAllWorkOrders({
        jobId: selectedJob,
      });
      const workOrderResponse = workOrderResponseByJobId?.data?.filter(
        (data: { jobId: string }) => data?.jobId === selectedJob
      );
      setStatus(workOrderResponse?.[0]?.status);
      if (workOrderResponse?.[0]?.jobStartDate) {
        const startDate = new Date(workOrderResponse?.[0]?.jobStartDate);
        const endDate = new Date(workOrderResponse?.[0]?.jobEndDate);
        const formattedStartDate = formatDate(startDate.toISOString());
        const formattedEndDate = formatDate(endDate.toISOString());
        setJobStartDate(formattedStartDate);
        setJobEndDate(formattedEndDate);
      }
      setSelectedJobData(workOrderResponse?.[0]);
      setJobsDataLoading(false);
    } catch (e) {
      console.log('error', e);
      setJobsDataLoading(false);
    }
  };

  useEffect(() => {
    if (facilityId) {
      fetchSelectedFacilityData();
    }
  }, [facilityId]);

  useEffect(() => {
    if (selectedJob) {
      fetchSelectedJobData();
    }
  }, [selectedJob]);

  const getSelectedJob = () => {
    return selectedJob ? selectedJob : location.state.jobId;
  };

  return (
    <>
      <PageTitle title={t('dashboard:jobDetails')} />
      <CustomerContainer>
        <DropdownWrapper>
          <Dropdown
            isLoading={isFacilityDropDownLoading}
            dropdownData={facilities}
            defaultValue={facilityId}
            onChange={(value) => setFacilityId(value)}
            dropDownWidth={'100%'}
          />
          <Dropdown
            isLoading={jobsLoading}
            dropdownData={allJobs}
            defaultValue={selectedJob}
            onChange={(value) => setSelectedJob(value)}
            dropDownWidth={'100%'}
          />
        </DropdownWrapper>
        <TabSection>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              <TabTitle label={t('dashboard:overview')} {...a11yProps(0)} />
              {status && !(status == 'Created') ? <TabTitle label={t('dashboard:workTasks')} {...a11yProps(1)} /> : ''}
              {status && !(status == 'Created') && (role as UserType) === UserType.Vendor && (
                <TabTitle label={t('dashboard:associateOnTheJob')} {...a11yProps(2)} />
              )}
              {status && !(status == 'Created') && <TabTitle label={t('dashboard:actionItems')} {...a11yProps(3)} />}
            </Tabs>
          </TabsWrapper>
          {!facilityLoading && !jobsLoading && !jobsDataLoading ? (
            selectedFacility ? (
              <>
                <TabPanel value={tabSelected} index={0}>
                  {renderOverviewTab()}
                </TabPanel>
                {status && !(status == 'Created') ? (
                  <TabPanel value={tabSelected} index={1}>
                    <JobDetailsTasks
                      specificDate={specificDate}
                      jobStartDate={jobStartDate}
                      jobEndDate={jobEndDate}
                      control={control}
                      servicesList={serviceData}
                      selectedJob={getSelectedJob()}
                      selectedFacility={selectedFacility}
                      vendorTypes={vendorTypes}
                      newDate={newDate}
                    />
                  </TabPanel>
                ) : (
                  ''
                )}
                {/* {role !== UserType.Customer && ( */}
                <TabPanel value={tabSelected} index={3}>
                  <ActionItems />
                </TabPanel>
                {/* )} */}
                {status && !(status == 'Created') && (role as UserType) === UserType.Vendor && (
                  <TabPanel value={tabSelected} index={2}>
                    <AssociateCard
                      specificDate={specificDate}
                      jobStartDate={jobStartDate}
                      jobEndDate={jobEndDate}
                      control={control}
                      jobData={selectedJobData}
                      FacilityData={selectedFacilityData}
                      jobId={selectedJob}
                      jobsLoader={jobsLoading || jobsDataLoading}
                    />
                  </TabPanel>
                )}
              </>
            ) : (
              <NoDataFound />
            )
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </div>
          )}
        </TabSection>
        {/* <SnackBarWrapper>
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
              <Button
                icon={<img src={Icons.DownloadIcon} alt={t('altTexts:download')} />}
                iconDirection={IconDirection.Right}
                type={ButtonType.Primary}
                label="Download Pdf"
                onClick={() => console.log('clicked')}
              />
            }
          />
        </SnackBarWrapper> */}
      </CustomerContainer>
      {/* <ReworkTaskDetails
        openReworkTaskDetails={openReworkTaskDetails}
        handleReworkTaskDetailsClose={() => setOpenReworkTaskDetails(false)}
      /> */}
    </>
  );
};

export default JobDetailsOverview;
