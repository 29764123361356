/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  calculateWorkVolume,
  fetchDataFromS3Bucket,
  getAuthData,
  getFacilityIcon,
  getStoredCustomerDetails,
  NUMBER_OF_ITEMS_PER_PAGE,
  UserRoles,
} from '../../../../Shared/Utilities/utils';

import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { UpdateWorkOrderInput, UserRolesAndScopes, WorkOrderStatus } from '../../../../API';

import { IVendorJobAwardData } from '../../Models/Marketplace.model';

import VendorJobAward from './AwardJobCard';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { AwardCardContainer, LoaderTag, NoDataContainer, NoDataTag } from './Marketplace.Style';

const AwardedJobs = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [upcomingJob, setUpcomingJob] = useState<IVendorJobAwardData[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isPrimaryLoading, setIsPrimaryLoading] = useState<boolean>(false);
  const [loadingCardId, setLoadingCardId] = useState<string>('');
  const [viewDetailsLoading, setViewDetailsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const noDataImageStyles: React.CSSProperties = {
    width: '20rem',
  };

  /* eslint-disable sonarjs/cognitive-complexity */
  const fetchVendorUpcomingJob = async () => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails.customerId;
    const vendorCompanyName = customerDetails.organizationName;
    const { userId } = getAuthData();
    const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
    const loggedInUserId = userDetails?.data?.userId ?? '';
    setIsLoading(true);
    try {
      // const jobStartDate = getDateString(new Date());  // Will remove jobStartDate value once QA verify the change.
      const userScopeResponse = await JobCreateApiService.getUserScopes(userDetails?.data?.userId);
      let jobCreatorRoleName = '';
      const userScopeData = userScopeResponse?.data ?? [];
      if (!isEmpty(userScopeData)) {
        userScopeData?.forEach((userScope: UserRolesAndScopes) => {
          if (
            !isEmpty(userScope?.roles || []) &&
            userScope?.roles?.some((role: any) => role.roleName === UserRoles.AccountHolder)
          ) {
            jobCreatorRoleName = UserRoles.AccountHolder;
          } else if (
            !isEmpty(userScope?.roles || []) &&
            (userScope?.roles?.some((role: any) => role.roleName === UserRoles.AccountHolder) ||
              userScope?.roles?.some((role: any) => role.roleName === UserRoles.Admin))
          ) {
            jobCreatorRoleName = UserRoles.Admin;
          }
        });
      }

      if (isEmpty(jobCreatorRoleName) && !isEmpty(userScopeData) && !isEmpty(userScopeData[0]?.roles)) {
        // If user role name neither AccountHolder nor Admin
        jobCreatorRoleName = userScopeData[0]?.roles[0]?.roleName || '';
      }

      let upcomingJobResponse = await WorkOrdersApiService.getAllWorkOrders({
        vendorId,
        status: `${WorkOrderStatus.Created},${WorkOrderStatus.Accepted}`,
        jobCreatedBy: loggedInUserId,
        jobCreatorRoleName,
      });

      if (upcomingJobResponse.metadata.totalCount > NUMBER_OF_ITEMS_PER_PAGE) {
        upcomingJobResponse = await WorkOrdersApiService.getAllWorkOrders({
          vendorId,
          status: `${WorkOrderStatus.Created},${WorkOrderStatus.Accepted}`,
          jobCreatedBy: loggedInUserId,
          jobCreatorRoleName,
          pageNumber: 1,
          limit: upcomingJobResponse.metadata.totalCount,
        });
      }
      if (!isEmpty(upcomingJobResponse?.data)) {
        const selectedWorkOrder = upcomingJobResponse?.data ?? [];
        if (!isEmpty(selectedWorkOrder)) {
          const upcomingJobsWithFacilityInfo = await Promise.all(
            selectedWorkOrder?.map(async (job: any) => {
              try {
                let facilityId;
                if (job?.facilities) {
                  facilityId = job?.facilities[0]?.facilityId;
                } else {
                  facilityId = '';
                }
                const facilityAddressResponse = await FacilityApiService.facility(facilityId, job.customerId as string);
                const customerDetailsResponse = await CustomerApiService.getCustomerDetailsResponse(
                  job.customerId as string
                );

                const vendorLogoResponse = await fetchDataFromS3Bucket(
                  navigate,
                  `vendor/${vendorId}/company-logos/${vendorId}`
                );
                const customerLogoResponse = await fetchDataFromS3Bucket(
                  navigate,
                  `customer/${job.customerId}/company-logos/${job.customerId}`
                );
                let facilityIcon = '';
                if (
                  facilityAddressResponse &&
                  facilityAddressResponse?.errors?.length === 0 &&
                  facilityAddressResponse?.data &&
                  facilityAddressResponse?.data?.address?.logoUrl
                ) {
                  facilityIcon = (await getFacilityIcon(facilityAddressResponse?.data?.address?.logoUrl)) as string;
                }
                if (customerDetailsResponse.errors.length) {
                  setSnackbarMessage(customerDetailsResponse?.errors[0]?.message);
                  setSnackbarSeverity('error');
                  setSnackbarOpen(true);
                }
                const { legalName } = customerDetailsResponse?.data?.customer ?? '';

                return {
                  vendorIcon: vendorLogoResponse,
                  customerIcon: customerLogoResponse,
                  jobType: job.jobType,
                  customerCompanyName: legalName,
                  customerFacilityName: facilityAddressResponse?.data?.buildingName,
                  vendorCompanyName,
                  vendorFacilityName: vendorCompanyName,
                  jobName: job.name,
                  customerFacilityAddress:
                    (facilityAddressResponse.data?.address?.addressLine2 ||
                      facilityAddressResponse.data?.address?.addressName) ??
                    '',
                  jobStartDate: job?.jobStartDate ?? '',
                  jobEndDate: job?.jobEndDate ?? '',
                  totalAmount: job?.totalAmount,
                  workVolume: calculateWorkVolume(job?.services),
                  workOrderId: job?.workOrderId,
                  workOrderStatus: job?.status,
                  vendorId,
                  customerId: job.customerId,
                  bidId: job?.bidId ?? '',
                  jobId: job?.jobId ?? '',
                  facilityId: facilityId ?? '',
                  logo: facilityIcon,
                };
              } catch (error) {
                console.error('Error in processing job:', job, error);
                throw error;
              }
            })
          );
          setUpcomingJob(upcomingJobsWithFacilityInfo);
        } else {
          setUpcomingJob([]);
        }
      } else if (isEmpty(upcomingJobResponse?.data) && isEmpty(upcomingJobResponse?.errors)) {
        setUpcomingJob([]);
      } else if (upcomingJobResponse?.errors?.length && upcomingJobResponse?.errors[0]?.message) {
        setSnackbarMessage(upcomingJobResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error('Error', e);
    }
  };

  const updateWorkOrderStatus = async (payload: UpdateWorkOrderInput, name: string, customerId = '', jobId = '') => {
    setLoadingCardId(payload.workOrderId);
    setIsPrimaryLoading(true);
    try {
      const workOrderResponse = await WorkOrdersApiService.updateWorkOrderStatus(payload);
      if (workOrderResponse?.data && workOrderResponse?.errors?.length === 0) {
        navigateJobDetailsPage(customerId, jobId);
      } else {
        setSnackbarMessage(workOrderResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Error', e);
    }
    setIsPrimaryLoading(false);
  };

  const navigateJobDetailsPage = async (customerId: string, jobId: string) => {
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse.data && jobSummaryResponse.errors.length === 0) {
      const jobData = jobSummaryResponse.data;
      const facilityResponse = await FacilityApiService.facility(
        jobData.jobFacilities[0].facilityId,
        jobData.customerId
      );
      navigate(`/onetimejobdetails?id=${jobId}`, {
        state: {
          facilityItem: {
            ...jobSummaryResponse.data,
            address: facilityResponse.data.address || {},
            jobStatus: jobSummaryResponse.data.dataStatus,
          },
          facility: { ...jobData.jobFacilities[0], address: facilityResponse.data.address || {} },
          job: {
            ...jobSummaryResponse.data,
            address: facilityResponse.data.address || {},
            jobStatus: jobSummaryResponse.data.dataStatus,
          },
        },
      });
    } else {
      setSnackbarMessage(jobSummaryResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleViewDetails = async (jobId: string, customerId: string, workOrderId: string) => {
    setLoadingCardId(workOrderId);
    setViewDetailsLoading(true);
    await navigateJobDetailsPage(customerId, jobId);
    setViewDetailsLoading(false);
  };

  useEffect(() => {
    fetchVendorUpcomingJob();
  }, []);
  return (
    <>
      <AwardCardContainer>
        {isLoading ? (
          <LoaderTag>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </LoaderTag>
        ) : (
          <>
            {upcomingJob.length > 0 ? (
              <>
                {upcomingJob.map((job: IVendorJobAwardData) => {
                  return (
                    <VendorJobAward
                      data={job}
                      isPrimaryButtonLoading={isPrimaryLoading}
                      viewDetailsLoading={viewDetailsLoading}
                      loadingCardId={loadingCardId}
                      handleViewDetails={handleViewDetails}
                      handleStart={updateWorkOrderStatus}
                    />
                  );
                })}
              </>
            ) : (
              <NoDataContainer>
                <img src={Icons.ErrorCautionImg} style={{ ...noDataImageStyles }} />
                <NoDataTag>{t('vendor:noAwardedJobsMsg')}</NoDataTag>
              </NoDataContainer>
            )}
          </>
        )}
      </AwardCardContainer>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AwardedJobs;
