/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mui/material';

import { getMarketplaceTabs } from '../../Models/constants';

import { ITabPanelProps } from '../../Models/Marketplace.model';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';

import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { TabSection, TabTitle } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { TabsWrapper } from '../../../Jobs/Components/JobView/JobsView.styles';

function a11yProps(index: number) {
  return {
    id: `marketplace-tab-${index}`,
    'aria-controls': `marketplace-tabpanel-${index}`,
  };
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`marketplace-tabpanel-${index}`}
      aria-labelledby={`marketplace-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

const MarketplacePage = (): JSX.Element => {
  const marketplaceTabs = getMarketplaceTabs();
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);
  const location = useLocation();
  const pathVariables = location?.pathname.split('/');
  const tabIndex = marketplaceTabs.findIndex((tab) => tab.route === pathVariables[pathVariables.length - 1]);
  const [tabSelected, setTabSelected] = useState(tabIndex);

  const handleChangeTabs = useCallback(async (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    window.history.pushState(null, 'Peazy', `/marketplace/${marketplaceTabs[newValue].route}`);
  }, []);

  useEffect(() => {
    setTabSelected(tabIndex);
  }, [location.pathname]);

  return (
    <>
      <PageTitle title={t('vendor:marketplace')} />
      <CustomerContainer>
        <TabSection>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              {marketplaceTabs.map((tab, index) => (
                <TabTitle key={tab.label + index} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </TabsWrapper>
          {marketplaceTabs.map((tab, index) => (
            <TabPanel value={tabSelected} index={index} key={tab.label}>
              <tab.component />
            </TabPanel>
          ))}
        </TabSection>
      </CustomerContainer>
    </>
  );
};

export default MarketplacePage;
