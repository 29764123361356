/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'material-react-table';
import { isEmpty, isNaN } from 'lodash';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { styled, Box, Stack, CircularProgress, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import { buttonStyle, HeaderContainer, EstimationTableInput } from '../CreateBid.Style';

import { calculateTotalEstimationWithProfit } from '../../../../Shared/Utilities/utils';
import { PricingOptions, TagNames, UserType } from '../../../../Shared/Constants/App';
import theme from '../../../../Shared/Themes/theme';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import Dropdown from '../../../../Shared/Components/Common/Dropdown/Dropdown';
import { StyleConstants } from '../../../../Shared/Constants/Style.Constants';

export const Wrapper = styled('div')(() => ({
  backgroundColor: theme.palette.common.white,
}));

export const HeadingText = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.255rem',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

interface EstimationTable {
  frequency: string;
  name: any;
  facilityStatus: string;
  serviceMeasure: string;
  numOfHours: number;
  ratePerHour: number;
  costPerService: number;
  servicesPerWeek: number;
  weeklyCost: number;
  profitPerWeek: number;
  weeklyBid: number;
  numOfWeeks: number;
  totalServices: number;
  totalJobEstimation: number;
}

interface BidEstimationTable {
  storedJob: any;
  estimationType: any;
  setTotalEstimation: any;
  setCopyTableData: any;
  profitPercentage: number;
  additionalSubTotal: any;
  vendorRatesAndTaxes: any;
  unionIndicator: any;
  isServicesLoading: any;
  setIsServicesLoading: (data: boolean) => void;
  ptiType: any;
  setPtiType: any;
  isEditable: any;
  isCardView: any;
  serviceData: any;
  pricingOptionsList: any[];
  selectedPricingOption: PricingOptions;
  setSelectedPricingOption: (data: PricingOptions) => void;
  bidDetails?: any;
  tag?: string;
  setIsDataLoading?: (data: boolean) => void;
  isDataLoading?: any;
}

interface ServiceRateDetails {
  serviceCategoryName: string;
  unionBlendedRate?: number;
  nonUnionBlendedRate?: number;
}

interface Item {
  ratePerHour?: number;
}

interface ServiceItem {
  name: string;
  service: string;
  mdServiceName: string;
  serviceMeasure: any;
  quantity: any;
  numOfHours: any;
  ratePerHour: any;
  totalServices: any;
  totalJobEstimation: any;
  mdServiceId: string;
  jobId: string;
  ptiTaxes: any;
  estimationId: any;
  unit: any;
  mdServiceUnits: any;
}

// const authValue = localStorage.getItem('auth');
// let role: string;
// if (authValue !== null) {
//   const authData = JSON.parse(authValue);
//   role = authData?.attributes?.['custom:Role'];
// } else {
//   // case where 'auth' is not found in localStorage
//   role = '';
// }

const BidEstimationTable = ({
  storedJob,
  estimationType = UserType.SubVendor,
  setTotalEstimation,
  setCopyTableData,
  vendorRatesAndTaxes,
  unionIndicator,
  isServicesLoading,
  ptiType,
  setPtiType,
  isEditable,
  isCardView,
  serviceData,
  pricingOptionsList,
  selectedPricingOption,
  setSelectedPricingOption,
  bidDetails,
  tag,
  setIsDataLoading,
  isDataLoading,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: BidEstimationTable): JSX.Element => {
  const { t } = useTranslation(['vendor']);
  const { radioButton, radioButtonText, executionType, serviceScheduleContent } = buttonStyle;

  const [tableData, setTableData] = useState<any[]>(() => []);
  const [subTotal, setSubTotal] = useState(0);

  const renderPriceCell = (renderedCellValue: any): string => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      renderedCellValue = parseFloat(String(renderedCellValue)).toFixed(2);
      return renderedCellValue ? `$ ${String(renderedCellValue)}` : '-';
    }
    return '-';
  };

  const renderCell = (renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  const renderPTICell = (renderedCellValue: React.ReactNode): string | number => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '0.00';
    }
    return '0.00';
  };

  const calculateHourlyRate = (
    item: Item,
    selectedPricingOption: PricingOptions,
    unionIndicator: string | null | undefined,
    serviceRateDetails: ServiceRateDetails
  ): number => {
    if (item.ratePerHour || item.ratePerHour === 0) {
      return item.ratePerHour;
    }
    if (selectedPricingOption !== PricingOptions.Hours) {
      return 0;
    }
    if (unionIndicator !== 'Non-Union' && unionIndicator !== null && unionIndicator !== undefined) {
      return serviceRateDetails?.unionBlendedRate ?? 0;
    } else {
      return serviceRateDetails?.nonUnionBlendedRate ?? 0;
    }
  };

  const calculateRatePerHour = (
    selectedPricingOption: PricingOptions,
    unionIndicator: string | null | undefined,
    serviceRateDetails: ServiceRateDetails
  ): number => {
    if (selectedPricingOption !== PricingOptions.Hours) {
      return 0;
    } else {
      if (unionIndicator !== 'Non-Union' && unionIndicator !== null && unionIndicator !== undefined) {
        return serviceRateDetails?.unionBlendedRate ?? 0;
      } else {
        return serviceRateDetails?.nonUnionBlendedRate ?? 0;
      }
    }
  };

  const getDataBasedOnEstimationType = () => {
    if (
      estimationType === UserType.Customer ||
      (estimationType === UserType.SubVendor && tag === TagNames.RejectOrRequestRevision)
    ) {
      return serviceData ?? '';
    } else {
      return tableData;
    }
  };

  const dataToRender = getDataBasedOnEstimationType();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTotalCell = (cellValue: string): JSX.Element => {
    if (estimationType == UserType.Customer) {
      let totalServicesCount = 0;

      serviceData.forEach((service: { [x: string]: any }) => {
        const cellVal = service[`${cellValue}`];
        if (cellVal) {
          totalServicesCount += parseFloat(cellVal);
        }
      });

      return (
        <Box>
          <Stack>
            <Box>
              {cellValue !== 'numOfHours' && cellValue !== 'totalServices'
                ? `$ ${totalServicesCount?.toFixed(2)}`
                : totalServicesCount}
            </Box>
          </Stack>
        </Box>
      );
    } else {
      let totalServicesCount = 0;

      tableData.forEach((service) => {
        const cellVal = service[`${cellValue}`];
        if (cellVal) {
          totalServicesCount += parseFloat(cellVal);
        }
      });
      // setTotalEstimation(totalServicesCount);
      return (
        <Box>
          <Stack>
            <Box>
              {cellValue !== 'numOfHours' && cellValue !== 'totalServices'
                ? `$ ${isNaN(totalServicesCount) ? '0.00' : totalServicesCount?.toFixed(2)}`
                : isNaN(totalServicesCount)
                  ? 0
                  : totalServicesCount?.toFixed(2)}
            </Box>
          </Stack>
        </Box>
      );
    }
  };

  const handleInputValue = (cell: any, value: any): void => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));
    if (value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      }
    } else {
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
    }
    if (selectedPricingOption === PricingOptions.FlatRate) {
      tableCopy[cell.row.index]['numOfHours'] = 1;
      tableCopy[cell.row.index]['ratePerHour'] = value;
    }
    setTableData([...tableCopy]);
    setCopyTableData([...tableCopy]);
  };

  const getModifiedTotalCost = (totalCost: number): number => {
    if (selectedPricingOption === PricingOptions.Hours) {
      if (ptiType === '1.5x') {
        return totalCost * 1.5 || 0;
      } else if (ptiType === 'DoublePay') {
        return totalCost * 2 || 0;
      } else {
        return totalCost || 0;
      }
    } else {
      return totalCost;
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSaveCell = (cell: any, value: any): void => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));

    let allowDecimalValue = false;
    if (value && value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        allowDecimalValue = true;
      } else {
        allowDecimalValue = false;
      }
    } else if (value && !value.includes('.')) {
      allowDecimalValue = true;
    }
    if (allowDecimalValue) {
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      const rowIndex = cell.row.index;
      const cellValue = parseFloat(value);

      const numOfHours = tableCopy[rowIndex]['numOfHours'] || 0;
      const ratePerHour = tableCopy[rowIndex]['ratePerHour'] || 0;
      const PTIPercentage = tableCopy[rowIndex]['ptiTaxes'] || 0;
      if (cell.column.id === 'numOfHours') {
        let totalCost;
        if (bidDetails?.isVendorBid) {
          totalCost = cellValue * ratePerHour;
        } else {
          totalCost =
            cellValue * ratePerHour * (selectedPricingOption === PricingOptions.Hours ? 1 + PTIPercentage / 100 : 1);
        }
        const modifiedTotalCost = getModifiedTotalCost(totalCost);
        tableCopy[rowIndex]['totalJobEstimation'] = isNaN(modifiedTotalCost) ? '0.00' : modifiedTotalCost;
        // const approxTotalCost = Math.round(modifiedTotalCost * 100) / 100;
        // tableCopy[rowIndex]['totalJobEstimation'] = isNaN(approxTotalCost) ? '0.00' : approxTotalCost.toFixed(2);
      } else if (cell.column.id === 'ratePerHour') {
        let totalCost;
        if (bidDetails?.isVendorBid) {
          totalCost = cellValue * numOfHours;
        } else {
          totalCost =
            cellValue * numOfHours * (selectedPricingOption === PricingOptions.Hours ? 1 + PTIPercentage / 100 : 1);
        }
        const modifiedTotalCost = getModifiedTotalCost(totalCost);
        tableCopy[rowIndex]['totalJobEstimation'] = isNaN(modifiedTotalCost) ? '0.00' : modifiedTotalCost;

        // const approxTotalCost = Math.round(modifiedTotalCost * 100) / 100;
        // tableCopy[rowIndex]['totalJobEstimation'] = isNaN(approxTotalCost) ? '0.00' : approxTotalCost.toFixed(2);
      } else if (cell.column.id === 'ptiTaxes') {
        const totalCost = numOfHours * ratePerHour * (1 + cellValue / 100);
        const modifiedTotalCost = getModifiedTotalCost(totalCost);
        tableCopy[rowIndex]['totalJobEstimation'] = isNaN(modifiedTotalCost) ? '0.00' : modifiedTotalCost;

        // const approxTotalCost = Math.round(modifiedTotalCost * 100) / 100;
        // tableCopy[rowIndex]['totalJobEstimation'] = isNaN(approxTotalCost) ? '0.00' : approxTotalCost.toFixed(2);
      } else {
        tableCopy[rowIndex]['totalJobEstimation'] = isNaN(cellValue) ? 0 : cellValue;
      }

      const finalTotalEstimation = tableCopy.reduce((total: number, item: any) => total + item.totalJobEstimation, 0);
      const totalWithProfilPercent = calculateTotalEstimationWithProfit(parseFloat(finalTotalEstimation || 0), 0);
      setTotalEstimation(totalWithProfilPercent);
      setSubTotal(finalTotalEstimation);
      setTableData([...tableCopy]);
      setCopyTableData([...tableCopy]);
    }
  };

  const getRateHeader = (): string => {
    return selectedPricingOption === PricingOptions.Hours
      ? 'Blended Hourly Rate'
      : `Blended Rate per ${selectedPricingOption}`;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const columns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(() => {
    let defaultColumns = [
      {
        accessorKey: 'name',
        header: 'Service Category',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }: any) => renderCell(renderedCellValue),
        Footer: () => t('vendor:subTotal'),
      },
      {
        accessorKey: 'mdServiceUnits',
        header: 'Serviceable Area',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }: any) => renderCell(renderedCellValue),
        Footer: () => '-',
      },
      {
        accessorKey: 'numOfHours',
        header: `No. of ${selectedPricingOption}`,
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 80,
        enableEditing: false,
        Cell: ({ cell, renderedCellValue }: any) => (
          <>
            <span>{!isEditable ? renderedCellValue : null}</span>
            {isEditable && (
              <EstimationTableInput
                sx={{
                  maxWidth: 'fit-content',
                }}
                type="text"
                value={renderedCellValue}
                placeholder={`No. of ${selectedPricingOption}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputValue(cell, event.target.value);
                  handleSaveCell(cell, event.target.value);
                }}
                // onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleSaveCell(cell, event.target.value)}
                onInput={(event: React.FormEvent<HTMLInputElement>) => {
                  const inputElement = event.target as HTMLInputElement;
                  const inputValue = inputElement.value;
                  const numericInput = inputValue.replace(/[^0-9.]/, '');
                  inputElement.value = numericInput;
                }}
              />
            )}
          </>
        ),
        Footer: () => renderTotalCell('numOfHours'),
      },
      {
        accessorKey: 'ratePerHour',
        header: getRateHeader(),
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ cell, renderedCellValue }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.2em',
            }}>
            <span>
              ${!isEditable ? (isNaN(renderedCellValue) ? '0.00' : parseFloat(renderedCellValue).toFixed(2)) : null}
            </span>
            {
              isEditable && (
                // (row.original.isStandardRateAvailable && selectedPricingOption === PricingOptions.Hours ? (
                //   <span>{renderedCellValue}</span>
                // ) : (
                <EstimationTableInput
                  type="text"
                  value={renderedCellValue}
                  placeholder="$0.00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputValue(cell, event.target.value);
                    handleSaveCell(cell, event.target.value);
                  }}
                  // onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleSaveCell(cell, event.target.value)}
                  onInput={(event: React.FormEvent<HTMLInputElement>) => {
                    const inputElement = event.target as HTMLInputElement;
                    const inputValue = inputElement.value;
                    const numericInput = inputValue.replace(/[^0-9.]/, '');
                    inputElement.value = numericInput;
                  }}
                />
              )
              // ))
            }
          </Box>
        ),
        Footer: () => '-',
      },
      {
        accessorKey: 'totalJobEstimation',
        header: selectedPricingOption === PricingOptions.FlatRate ? 'Service Cost' : 'Amount',
        size: 100,
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        // Cell: ({ renderedCellValue }: any) => renderPriceCell(renderedCellValue),
        Cell: ({ cell, renderedCellValue }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.2em',
            }}>
            <span>
              ${!isEditable ? (isNaN(renderedCellValue) ? '0.00' : parseFloat(renderedCellValue).toFixed(2)) : null}
            </span>
            {isEditable &&
              (selectedPricingOption !== PricingOptions.FlatRate ? (
                // <span>{renderedCellValue}</span>
                <span>{isNaN(renderedCellValue) ? '0.00' : parseFloat(renderedCellValue).toFixed(2)}</span>
              ) : (
                <EstimationTableInput
                  type="text"
                  value={renderedCellValue}
                  placeholder="$0.00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputValue(cell, event.target.value)}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleSaveCell(cell, event.target.value)}
                  onInput={(event: React.FormEvent<HTMLInputElement>) => {
                    const inputElement = event.target as HTMLInputElement;
                    const inputValue = inputElement.value;
                    const numericInput = inputValue.replace(/[^0-9.]/, '');
                    inputElement.value = numericInput;
                  }}
                />
              ))}
          </Box>
        ),
        Footer: () => renderTotalCell('totalJobEstimation'),
      },
    ];
    // Conditionally add the 'ptiTaxes' column if bidDetails?.isVendorBid is false i.e, for OTJ
    if (!bidDetails?.isVendorBid) {
      const ptiTaxesColumn = {
        accessorKey: 'ptiTaxes',
        header: 'PTI %',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }: any) => renderPTICell(renderedCellValue),
        Footer: () => '-',
      };

      // Inserting 'ptiTaxes' column after 'ratePerHour' column
      const ratePerHourIndex = defaultColumns.findIndex((column) => column.accessorKey === 'ratePerHour');
      defaultColumns.splice(ratePerHourIndex + 1, 0, ptiTaxesColumn);
    }

    if (selectedPricingOption === PricingOptions.FlatRate) {
      defaultColumns = defaultColumns.filter(
        (columnData: any) =>
          columnData.accessorKey !== 'numOfHours' &&
          columnData.accessorKey !== 'ratePerHour' &&
          columnData.accessorKey !== 'ptiTaxes'
      );
    } else if (selectedPricingOption === PricingOptions.Unit || selectedPricingOption === PricingOptions.Sqft) {
      defaultColumns = defaultColumns.filter((columnData: any) => columnData.accessorKey !== 'ptiTaxes');
    }
    return defaultColumns;
  }, [tableData, subTotal, selectedPricingOption]);

  const customerColumns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'service',
        header: 'Service Category',
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'totalJobEstimation',
        header: 'Amount',
        size: 100,
        enablePinning: true,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => renderPriceCell(renderedCellValue),
      },
    ],
    [serviceData]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchData = (): void => {
    try {
      const jobServices =
        storedJob?.subJobServices && estimationType === UserType.SubVendor && tag === TagNames.RejectOrRequestRevision
          ? storedJob?.subJobServices
          : storedJob?.jobServices;

      const getServiceName = (item: ServiceItem) => {
        if (item?.name) {
          return item?.name;
        } else if (item.service) {
          return item.service;
        } else if (item?.mdServiceName) {
          return item?.mdServiceName;
        }
      };
      const servicesData = jobServices?.map((item: ServiceItem) => {
        const serviceName = getServiceName(item);
        const serviceRateDetails = vendorRatesAndTaxes?.find(
          (service: { serviceCategoryName: any }) => service.serviceCategoryName === serviceName
        );
        let nonUnionPtiPercentage,
          unionPtiPercentage = 0;
        if (!isEmpty(vendorRatesAndTaxes)) {
          nonUnionPtiPercentage = vendorRatesAndTaxes[0].nonUnionPtiPercentage ?? 0;
          unionPtiPercentage = vendorRatesAndTaxes[0].unionPtiPercentage ?? 0;
        }

        return {
          name: getServiceName(item),
          service: item.name ?? item.service,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          serviceMeasure: item.serviceMeasure
            ? item.serviceMeasure
            : selectedPricingOption
              ? selectedPricingOption
              : PricingOptions.Hours,
          mdServiceUnits: item.unit ?? item.mdServiceUnits,
          numOfHours: item.numOfHours ? item.numOfHours : 0,
          // ratePerHour: item.ratePerHour ? item.ratePerHour : 0,
          ratePerHour: calculateHourlyRate(item, selectedPricingOption, unionIndicator, serviceRateDetails),
          totalServices: item.totalServices ? item.totalServices : 1,
          totalJobEstimation: item.totalJobEstimation ? parseFloat(item.totalJobEstimation) : 0,
          mdServiceId: item.mdServiceId,
          jobId: item.jobId,
          ptiTaxes: item.ptiTaxes
            ? item.ptiTaxes
            : unionIndicator !== 'Non-Union' && unionIndicator !== null && unionIndicator !== undefined
              ? unionPtiPercentage
              : nonUnionPtiPercentage,
          isStandardRateAvailable:
            unionIndicator !== 'Non-Union' &&
            unionIndicator !== null &&
            unionIndicator !== undefined &&
            !isEmpty(serviceRateDetails)
              ? !!serviceRateDetails?.unionBlendedRate
              : !!serviceRateDetails?.nonUnionBlendedRate,
          estimationId: item.estimationId ?? '',
        };
      });

      if (estimationType === UserType.SubVendor) {
        setTableData(servicesData ? [...servicesData] : []);
        setCopyTableData(servicesData ? [...servicesData] : []);
      } else {
        setTableData(servicesData ? [...servicesData] : []);
      }
    } catch (error) {
      // Handle any errors that may occur during fetching the data
      console.error('Error fetching data:', error);
    }
  };

  const handlePtiTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPtiType(event.target.value);
  };

  useEffect(() => {
    if (isEditable) {
      const tableDataCopy = tableData.map((service) => {
        let totalAmount;
        if (selectedPricingOption === PricingOptions.FlatRate) {
          totalAmount = parseFloat(service.ratePerHour);
        } else {
          const totalCost =
            service.numOfHours *
            service.ratePerHour *
            (selectedPricingOption === PricingOptions.Hours ? 1 + service.ptiTaxes / 100 : 1);
          totalAmount = getModifiedTotalCost(totalCost);
        }
        return {
          ...service,
          totalJobEstimation: totalAmount,
        };
      });
      if (tableDataCopy.length > 0) {
        setTableData(tableDataCopy);
      }
      const finalTotalEstimation = tableDataCopy.reduce((total, item) => total + item.totalJobEstimation, 0);
      setTotalEstimation(finalTotalEstimation);
      setCopyTableData([...tableDataCopy]);
    }
  }, [ptiType, selectedPricingOption]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (setIsDataLoading) {
      setIsDataLoading(true);
    }
    if (isEditable) {
      if (selectedPricingOption !== PricingOptions.Hours) setPtiType('NoOverTime');
      const tableDataCopy = tableData.map((service) => {
        const serviceRateDetails = vendorRatesAndTaxes?.find(
          (serviceDetails: { serviceCategoryName: any }) => serviceDetails.serviceCategoryName === service.name
        );

        return {
          ...service,
          numOfHours: selectedPricingOption === PricingOptions.FlatRate ? 1 : 0,
          ratePerHour: calculateRatePerHour(selectedPricingOption, unionIndicator, serviceRateDetails),
          totalJobEstimation: 0,
        };
      });
      if (tableDataCopy.length > 0) {
        setTableData(tableDataCopy);
      }
      const finalTotalEstimation = tableDataCopy.reduce((total, item) => total + item.totalJobEstimation, 0);
      setTotalEstimation(finalTotalEstimation);
      setCopyTableData([...tableDataCopy]);
    }
    const timer = setTimeout(() => {
      if (setIsDataLoading) {
        setIsDataLoading(false);
      }
    }, 5000);
    return (): void => clearTimeout(timer);
  }, [selectedPricingOption]);

  useEffect(() => {
    setTotalEstimation(tableData.reduce((total, item) => total + item.totalJobEstimation, 0));
  }, [tableData]);

  useEffect(() => {
    if (storedJob?.jobServices?.length > 0) {
      fetchData();
    }
  }, [storedJob?.jobServices?.length, vendorRatesAndTaxes, unionIndicator]);

  const showLoader = () => {
    return isServicesLoading || isDataLoading;
  };

  return (
    <>
      <HeaderContainer
        sx={
          isCardView
            ? {
                display: 'flex',
                flex: 1,
                justifyContent: StyleConstants.JustifyContentBetween,
                flexDirection: 'row',
                flexWrap: 'wrap',
              }
            : {
                display: 'flex',
                flex: 1,
                justifyContent: StyleConstants.JustifyContentBetween,
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxShadow: 'unset',
                padding: 0,
              }
        }>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: StyleConstants.JustifyContentBetween, alignItems: 'center' }}>
            <HeadingText sx={{ color: theme.palette.text.secondary }}>{t('vendor:bidEstimation')}</HeadingText>
            {estimationType !== UserType.Customer && (
              <Box
                sx={{
                  ...serviceScheduleContent,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '1em',
                  opacity: !isEditable || isServicesLoading ? 0.5 : 1,
                }}>
                <FormControl
                  component="fieldset"
                  sx={{
                    width: '100%',
                    pointerEvents: !isEditable || isServicesLoading ? 'none' : 'all',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem',
                  }}>
                  {selectedPricingOption === PricingOptions.Hours && (
                    <RadioGroup
                      row
                      value={ptiType}
                      onChange={handlePtiTypeChange}
                      sx={{ ...executionType, display: 'flex', gap: '1em' }}>
                      {!bidDetails?.isVendorBid && tag !== TagNames.RejectOrRequestRevision && (
                        <>
                          <Box>
                            <FormControlLabel
                              value="NoOverTime"
                              control={
                                <Radio
                                  sx={{
                                    ...radioButton,
                                    pointerEvents: !isEditable || isServicesLoading ? 'none' : 'all',
                                  }}
                                />
                              }
                              label={t('oneTimeJob:noOverTime')}
                              sx={{
                                ...radioButtonText,
                              }}
                            />
                          </Box>
                          <Box>
                            <FormControlLabel
                              value="1.5x"
                              control={
                                <Radio
                                  sx={{
                                    ...radioButton,
                                    pointerEvents: !isEditable || isServicesLoading ? 'none' : 'all',
                                  }}
                                />
                              }
                              label={t('oneTimeJob:1.5x')}
                              sx={{
                                ...radioButtonText,
                              }}
                            />
                          </Box>
                        </>
                      )}
                      {/* <Box>
                      <FormControlLabel
                        value="DoublePay"
                        control={
                          <Radio
                            sx={{
                              ...radioButton,
                              pointerEvents: !isEditable ? 'none' : 'all',
                            }}
                          />
                        }
                        label={t('oneTimeJob:doublePay')}
                        sx={{
                          ...radioButtonText,
                        }}
                      />
                    </Box> */}
                    </RadioGroup>
                  )}
                  {tag !== TagNames.RejectOrRequestRevision && (
                    <Dropdown
                      dropdownData={pricingOptionsList}
                      defaultValue={selectedPricingOption}
                      onChange={(value: string) => setSelectedPricingOption(value as PricingOptions)}
                      width={'10rem'}
                      labelName={t('oneTimeJob:pricingOptions')}
                    />
                  )}
                </FormControl>
              </Box>
            )}
          </div>
          {showLoader() ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : (
            <DataGrid
              columns={
                estimationType == UserType.Customer ||
                (estimationType === UserType.SubVendor && tag === TagNames.RejectOrRequestRevision)
                  ? customerColumns
                  : columns
              }
              data={dataToRender}
              enableColumnPinning={true}
              enableRowSelect={false}
              enableEditing={true}
              rightPinArr={['weeklyBid']}
              leftPinArr={['name', 'serviceMeasure']}
              handleSaveCell={handleSaveCell}
            />
          )}
        </div>
      </HeaderContainer>
    </>
  );
};

export default BidEstimationTable;
